<template>
  <nav class="navbar sticky navbar-expand-lg navbar-expand-md navbar-light">
    <div class="container-fluid px-0">
      <router-link class="navbar-brand pl-0 d-none" to="/home">
        <img alt="" src="../../assets/images/jta_logo.png">
      </router-link>
      <p class="logo-title mb-0 d-none">健康情報管理システム<br>HeaLo</p>
      <h5 class="page-title mb-0">{{ title }}</h5>
      <div id="navbarSupportedContent" class="collapse navbar-collapse border-0 navbar-supported-content">
        <ul class="navbar-nav navbar-mainmenu me-auto mb-2 mb-lg-0">

        </ul>
        <ul class="navbar-nav mr-auto small-screen d-none">
          <li class="nav-item last-child dropdown">
            <div class="row ">
              <div class="col d-flex">
                <div class="m-l-10">
                  <a id="" aria-expanded="false" class="nav-link" data-bs-toggle="dropdown" href="#" role="button">
                    <img class="account-icon" src="../../assets/images/account.svg">
                  </a>
                </div>
                <div class="m-l-10">
                  <a class="nav-link py-0" href="#">{{ lastName }} {{ firstName }}さん</a>
                  <a class="nav-link py-0" href="#">{{ lastName }} {{ firstName }}さん</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav navbar-usermenu mr-auto">
          <li class="nav-item last-child dropdown">
            <a id="navbarDropdownMenuLink" aria-expanded="false" class="nav-link" data-bs-toggle="dropdown"
               href="#" role="button">
              <img class="account-icon" src="../../assets/images/account.svg">
            </a>
            <ul aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu custom-dropdown-menu">
              <li><a class="dropdown-item no-link pb-0" href="javascript: void(0);">{{ lastName }} {{ firstName }}さん</a>
              </li>
              <li>
                <a class="dropdown-item no-link pt-0" href="javascript: void(0);">
                  <span class="user-id">{{ userId }}</span>
                  <div class="rounded user-roll">
                    <svg fill="#bbb" height="12.39" viewBox="0 0 11.218 12.39" width="11.218"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1.135,12.59A.949.949,0,0,1,.2,11.557c0-1.032.935-4.13,5.609-4.13s5.609,3.1,5.609,4.13a.949.949,0,0,1-.935,1.032ZM5.809,6.395a2.576,2.576,0,0,0,1.073-.236,2.8,2.8,0,0,0,.91-.671,3.137,3.137,0,0,0,.608-1,3.4,3.4,0,0,0,0-2.371,3.137,3.137,0,0,0-.608-1,2.8,2.8,0,0,0-.91-.671A2.576,2.576,0,0,0,5.809.2a2.677,2.677,0,0,0-1.983.907A3.269,3.269,0,0,0,3,3.3a3.269,3.269,0,0,0,.821,2.19,2.677,2.677,0,0,0,1.983.907Z"
                          transform="translate(-0.2 -0.2)"/>
                    </svg>
                    大会管理者
                  </div>
                </a>
              </li>
              <li class="border-top">
                <router-link class="dropdown-item" to="/account">アカウント設定</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/credit">HeaLoとは</router-link>
              </li>
              <li><a class="dropdown-item cursor-pointer" v-on:click="doLogout">ログアウト</a></li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto custom-small-screen d-none">
          <div class="row">
            <li>
              <router-link class="nav-link" to="/account">アカウント設定</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/credit">HeaLoとは</router-link>
            </li>
            <li><a class="nav-link cursor-pointer" v-on:click="doLogout">ログアウト</a></li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {app} from '@/constants'
import {mapGetters, mapActions} from "vuex";
import Common from "jsadminclient/common";

export default {
  name: "NavBar",
  data() {
    return {
      userId: '',
      app: app,
      response: {},
      loading: true,
      logoutError: false,
      lastName: '',
      firstName: '',
      title: 'ホーム'
    }
  },
  watch: {
    '$route'(to, from) {
      this.setTitle()
      if (to.name === "Login") {
        this.lastName = ''
        this.firstName = ''
        this.userId = ''
      } else if (from.name === "EditName") {
        // force Update
        this.getUser()
      } else if (this.userId === '' || this.userId === null) {
        this.getUser()
      }
    }
  },
  mounted() {
    this.getUser()
    this.userId = this.config.userId
    this.setTitle()
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    ...mapActions(['logout']),
    setTitle() {
      var setTitle = this.app.brand

      if (this.$route.meta.title) {
        this.title = this.$route.meta.title
        setTitle = setTitle + ' - ' + this.title
      }
      document.title = setTitle
    },
    async getUser() {
      const user_id = this.$store.getters.config.userId
      const token = this.$store.getters.config.token
      if (user_id !== null && token !== null) {
        try {
          const api = Common.createUserApi(token)
          const jsonObject = await api.usersUserIdGetAsync(user_id)
          if (jsonObject.requireUserInfo === false) {
            this.userId = jsonObject.userId
            this.lastName = jsonObject.lastName
            this.firstName = jsonObject.firstName
          }
        } catch (error) {
          const errorResult = error.response.data
        }
      }
    },
    async doLogout() {
      this.logout()
          .then((result) => {
            this.lastName = ''
            this.firstName = ''
            this.userId = ''

            this.$router.push({name: 'Login'})
          })
          .catch((error) => {
            // 何もしない
          })
    }

  },
}
</script>

<style lang="scss" scoped>

.logo-title {
  font-weight: bold;
  color: #002947;
  font-size: smaller;
}

.account-icon {
  height: 32px;
  width: 32px;
}

ul li {
  font-weight: bold;
  color: #002947;
}

.custom-dropdown-menu {
  background-color: #FFFFFF;
  border: 1px solid #DACED1;

  .dropdown-item {
    font-weight: bold;
    color: #333333;

    &:hover {
      background-color: #FAF7F9;
    }

    .user-id {
      position: relative;
      top: 3px;
    }

    .user-roll {
      background-color: #BBBBBB1A;
      padding-left: 4px;
      padding-right: 4px;
      line-height: 2;
      color: #555555;
      font-weight: 400;
      font-size: 0.6rem;
      display: inline-block;
    }
  }

  .no-link {
    font-weight: bold;
    color: #888888;
    padding-right: 0;
    cursor: text;
  }

  position: fixed !important;
  top: 43px;
  right: 16px;
  left: auto !important;
  @media (max-width: 992px) {
    top: 48px;
    right: 9px;
  }

  &.show {
    display: inline-block;
  }
}

a.router-link-active.router-link-exact-active.nav-link {
  color: #3490dc;
}

a.router-link-active.router-link-exact-active.dropdown-item {
  color: #3490dc;
}

.navbar {
  background-color: #FFFFFF;
  padding: 0 !important;

  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
  }
}

img {
  width: 58px;
  height: auto;
}

.small-screen .row {
  margin-left: -24px;
  border-bottom: 1px solid #D3D8EA;
  margin-right: 0;
  padding: 10px 0px;
}

.small-screen .row .col-2 > a {
  padding-left: 10px;
}

.custom-small-screen {
  margin-left: -24px;
  border-top: 1px solid #D3D8EA;
  margin-right: 0;
  padding: 10px 24px;
}

.navbar {
  border-bottom: 2px solid #DACED1;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .navbar {
    padding: 5.36px 20px !important;
  }

  .small-screen, .custom-small-screen {
    display: none;
  }

  .container-fluid {
    border-bottom: none;
  }
}

.nav {
  &bar {
    &-toggler {
      &, &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &-brand {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0 !important;

      img {
        width: auto !important;
        height: 44px !important;
      }
    }

    .logo-title {
      padding-top: 0;
      font-size: 10px;
      line-height: 13px;
      position: absolute;
      left: 72px;
      @media (max-width: 992px) {
        position: absolute;
        top: 16px;
        left: 74px;
      }
    }

    @media (max-width: 992px) {
      border-bottom-width: 2px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-left: 10px !important;
      .container-fluid {
        padding-left: 0 !important;
      }
      &-brand {
        padding-left: 11px !important;
      }
    }

    &-nav.custom-small-screen {
      @media (max-width: 992px) {
        padding-top: 10px;
        padding-bottom: 0;
        .nav-link {
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 1;
        }
      }
    }

    .navbar-supported-content {
      margin-right: -12px;
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }
  }

  &-item {

  }

  &-link {
    &.skippable {
      padding-right: 0 !important;
    }
  }
}

// Top Navigation bar:END
</style>
