<template>
  <div v-if="loading" class="ml-4 loader">
    <DotLoader></DotLoader>
  </div>
  <div v-else id="layout-wrapper" class="layout-wrapper">
    <div v-if="$route.name" class="container-fluid">
      <div class="row">
        <SideBar v-if="!alowedRoutes.includes($route.name)"></SideBar>
        <main id="main" :class="'main-' + routeClass" class="main col ml-sm-auto" role="main">
          <NavBar></NavBar>
          <div :class="routeClass" class="content">
            <slot></slot>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar'
import NavBar from "./NavBar";
import Footer from "./Footer"
import DotLoader from '../loaders/DotLoader'

export default {
  name: 'BaseLayout',
  components: {NavBar, Footer, DotLoader, SideBar},
  data() {
    return {
      loading: true,
      alowedRoutes: [
        'Login',
        'LoginWithDob',
        'InitialLoginFirst',
        'InitialLoginSecond',
        'InitialLoginThird',
        'ResetPassword',
        'ResetPasswordEmailSent',
        'ChangePassword'
      ],
    }
  },
  computed: {
    routeClass() {
      let routeClass = this.$route.name.toLowerCase()
      if (this.alowedRoutes.includes(this.$route.name)) {
        routeClass += ' left-0'
      }
      return routeClass
    }
  },
  mounted() {
    this.loading = false

  },
  methods: {
    hasNavbar() {

    }
  }

}

</script>

<style lang="scss" scoped>
.layout-wrapper {

}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

.main {
  background-color: #FFFFFF;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 80px;
  z-index: 1;
}

.content {
  position: relative;
  top: 30px;

  &.userrecord {
    top: 9px !important;
  }

  &.editmedicalhistory {
    top: 15px !important;
  }

  &.dblogs {
    top: 0 !important;
  }

  &:not(.toggled) {
    @media (max-width: 992px) {
      position: absolute;
      right: 0;
      left: 80px;
      margin-top: 70px;
    }
  }
}

</style>
