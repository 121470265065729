import { LoginApi, UserApi, ApiConfig, SystemApi} from './index'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import store from '../../store'

export default class {
    constructor () {

    }

    static getBasePath () {
        return gBasePath
    }

    static getClientKey () {
        return gClientKey
    }

    /**
     *
     * @returns {LoginApi}
     */
    static createLoginApi () {
        const basePath = gBasePath
        const clientKey = gClientKey
        const headers = {'X-Client-Key': clientKey}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new LoginApi(apiConfig)
        return api
    }

    /**
     *
     * @param apiToken
     * @returns {UserApi}
     */
    static createUserApi (apiToken) {
        const basePath = gBasePath
        const headers = {'X-API-Token': apiToken}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new UserApi(apiConfig)
        return api
    }

    /**
     *
     * @param apiToken
     * @returns {UserApi}
     */
    static createSystemApi (apiToken) {
        const basePath = gBasePath
        const headers = {'X-API-Token': apiToken}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new SystemApi(apiConfig)
        return api
    }
    
    /**
     * オブジェクトから安全にプロパティの値を取得する
     * @param {Object} obj
     * @param {String} key
     * @param {*} defaultValue
     * @returns {*}
     */
    static getProperty (obj, key, defaultValue = null) {
        if (obj.hasOwnProperty(key)) {
            return obj[key]
        } else {
            return defaultValue
        }
    }

    /**********************************************************/
    /* 保存処理 */
    /**********************************************************/
    static saveConfig (config) {
        localStorage.setItem('config', JSON.stringify(config))
    }

    static loadConfig () {
        return JSON.parse(localStorage.getItem('config'))
    }

    static saveFirstLogin () {
        localStorage.setItem('first_login', 1)

        // For compatibility
        document.cookie = "first_login";
    }
    static isFirstLogin() {
        if (localStorage.getItem('first_login') == 1) {
            return false
        }

        // For compatibility
        if(document.cookie != ""){
            var tmp = document.cookie.split('; ');
            for(var i=0; i<tmp.length; i++){
                // Consider safari
                if (tmp[i] == "first_login" || tmp[i] == "first_login=") {
                    return false
                }
            }
        }

        return true;
    }

    static saveCookie (key, value) {
        if (value === null) {
            document.cookie = key + "=; max-age=0";
        } else {
            let date = new Date()
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
            const expires = '; expires=' + date.toGMTString()
            document.cookie = key + "=" + value + expires + '; path=/';
        }
    }

    static getCookieString (key) {
        try {
            var tmp = document.cookie.split('; ');
            const cookieValue = document.cookie
              .split('; ')
              .find(row => row.startsWith(key))
            if (cookieValue) {
                return cookieValue.split('=')[1]
            }
            return null
        } catch (e) {
            return null
        }
    }

    static saveSelectTournamentIdCommon (value) {
        this.saveCookie("select_tournament_id", value)
    }

    static getSelectTournamentIdCommon() {
        const value = this.getCookieString("select_tournament_id")
        if (value !== null) {
            return Number(value)
        }
        return 0
    }

    static saveSelectTournamentIdForRecord (value) {
        this.saveCookie("select_tournament_id_record", value)
    }

    static getSelectTournamentIdForRecord() {
        const value = this.getCookieString("select_tournament_id_record")
        if (value !== null) {
            return Number(value)
        }
        return 0
    }

    static saveHomeStartDate (value) {
        if (value === null) {
            this.saveCookie("home_start_date", null)
            return
        }
        this.saveCookie("home_start_date", moment(value).format("YYYY-MM-DD"))
    }

    static getHomeStartDate() {
        const value = this.getCookieString("home_start_date")
        if (value !== null) {
            return moment(value).format("YYYY-MM-DD")
        }
        return null
    }

    static saveHomeEndDate (value) {
        if (value === null) {
            this.saveCookie("home_end_date", null)
            return
        }
        this.saveCookie("home_end_date", moment(value).format("YYYY-MM-DD"))
    }

    static getHomeEndDate() {
        const value = this.getCookieString("home_end_date")
        if (value !== null) {
            return moment(value).format("YYYY-MM-DD")
        }
        return null
    }

    static saveDBlogsStartDate (value) {
        if (value === null) {
            this.saveCookie("dblogs_start_date", null)
            return
        }
        this.saveCookie("dblogs_start_date", moment(value).format("YYYY-MM-DD"))
    }

    static getDBlogsStartDate() {
        const value = this.getCookieString("dblogs_start_date")
        if (value !== null) {
            return moment(value).format("YYYY-MM-DD")
        }
        return null
    }

    static saveDBlogsEndDate (value) {
        if (value === null) {
            this.saveCookie("dblogs_end_date", null)
            return
        }
        this.saveCookie("dblogs_end_date", moment(value).format("YYYY-MM-DD"))
    }

    static getDBlogsEndDate() {
        const value = this.getCookieString("dblogs_end_date")
        if (value !== null) {
            return moment(value).format("YYYY-MM-DD")
        }
        return null
    }




    static getSelectTournamentIdForIPUser() {
        const value = this.getCookieString("select_tournament_id_ipuser")
        if (value !== null) {
            return Number(value)
        }
        return 0
    }

    static saveSelectTournamentIdForIPUser (value) {
        this.saveCookie("select_tournament_id_ipuser", value)
    }

    static getSelectTournamentIdForReminder() {
        const value = this.getCookieString("select_tournament_id_reminder")
        if (value !== null) {
            return Number(value)
        }
        return 0
    }

    static saveSelectTournamentIdForReminder (value) {
        this.saveCookie("select_tournament_id_reminder", value)
    }

    static getSelectTournamentIdForScanLog() {
        const value = this.getCookieString("select_tournament_id_scan_log")
        if (value !== null) {
            return Number(value)
        }
        return 0
    }

    static saveSelectTournamentIdForScanLog (value) {
        this.saveCookie("select_tournament_id_scan_log", value)
    }

    static appendSearchQuery(orgQuery = null, searchQuery = null) {
        var search2 = {}
        if (orgQuery !== null) {
            for (let key in orgQuery) {
                if (orgQuery.hasOwnProperty(key)) {
                    if (Array.isArray(orgQuery[key])) {
                        // array -> string
                        search2[key] = orgQuery[key].join(',')
                    } else {
                        search2[key] = orgQuery[key]
                    }
                }
            }
        }

        if (searchQuery !== null) {
            for (let key in searchQuery) {
                if (searchQuery.hasOwnProperty(key)) {
                    if (Array.isArray(searchQuery[key])) {
                        // array -> string
                        search2[key] = searchQuery[key].join(',')
                    } else {
                        search2[key] = searchQuery[key]
                    }
                }
            }
        }

        return search2;
    }

    static removeSearchQuery(orgQuery = null, removeKeys = null) {
        var search2 = {}
        if (orgQuery !== null) {
            for (let key in orgQuery) {
                if (orgQuery.hasOwnProperty(key)) {
                    if (Array.isArray(orgQuery[key])) {
                        // array -> string
                        search2[key] = orgQuery[key].join(',')
                    } else {
                        search2[key] = orgQuery[key]
                    }
                }
            }
        }

        if (removeKeys !== null) {
            for (let value of removeKeys){
                if (search2.hasOwnProperty(value)) {
                    delete search2[value]
                }
            }
        }

        return search2;
    }


    static restoreQuery(obj, param, query = null) {
        if (query === null) {
            return
        }
        Apv.$set(obj, param, query)
    }

    static updateCurrentPageQuery(self, newQuery) {
        self.$router.replace({ query: newQuery }, () => {}, () => {});
    }

    static getNumberOrNull(value) {
        const num = Number(value)
        if (isNaN(num)) {
            return null
        }
        return num
    }

    /**********************************************************/
    // Convert date format to "yyyy/mm/dd"
    /**********************************************************/
    static dateFormatConvert (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY/MM/DD')
    }
    static dateFormatConvertForDatePicker (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY-MM-DD')
    }
    static getYear (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY')
    }

    static getMonth (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('MM')
    }

    static getDate (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('DD')
    }
    static dateFormatConvertWeekDay (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        obj.locale("ja");
        return obj.format('YYYY/MM/DD(ddd)')
    }

    static getToday() {
        return moment().format("YYYY-MM-DD")
    }

    /**********************************************************/
    // get gender
    /**********************************************************/
    static getGender (gender) {
        switch (gender) {
            case 1:
                return '男性'
            case 2:
                return '女性'
            case 3:
                return '回答しない'
        }
    }

    /**********************************************************/
    // get getLatestPcrTestResult
    /**********************************************************/
    static getLatestPcrTestResult (getLatestPcrTestResult) {
        switch (getLatestPcrTestResult) {
            case -1:
                return '陰性'
            case 0:
                return '未検査'
            case 1:
                return '陽性'
            default:
                return null
        }
    }

    /**********************************************************/
    // get hasCoronaVaccination
    /**********************************************************/
    static getHasCoronaVaccination (hasCoronaVaccination) {
        if (hasCoronaVaccination) {
            return '2回目'
        } else {
            return 'なし'
        }
    }

    static getHasCoronaVaccination1 (coronaVaccinationCount) {
        switch(coronaVaccinationCount){
            case 0:
                return 'なし'
              case 1:
                return '1回目'
              case 2:
                return '2回目'
              case 3:
                return '3回目'
              case 4:
                return '4回目'
              case 5:
                return '5回目'
              case 6:
                return '6回目'
              case 7:
                return '7回目'
              case 8:
                return '8回目'
              case 9:
                return '9回目'
              case 10:
                return '10回目'
        }
    }
    /**********************************************************/
    // get error list
    /**********************************************************/
    static getErrorList (response, errors) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.validationErrors !== null) {
                res.validationErrors.forEach(e => Apv.$set(errors, e.name, e.message))
                return errors
            } else {
                // Other error
            }
        }
        return [];
    }

    static isTokenError (response) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.result === "ERR_EXPIRE_TOKEN" || res.result === "ERR_INVALID_TOKEN") {
                store.commit('configToken', null)
                store.commit('configUserId', null)
                return true
            }
            return false
        } else {
            return false
        }
    }

    static isNotFoundError (response) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.result === "ERR_NOTFOUND") {
                return true
            }
            return false
        } else {
            return false
        }
    }

    static checkFirstLogin (response) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.result === "ERR_LOGIN_REQUIRE_BIRTHDAY") {
                return true
            }
        }
        return false
    }

    static handleAPIError (error, message = null) {
        if (error.response !== null && typeof(error.response) !== "undefined") {
            // Is API Error?
            if (error.response.data !== null && typeof(error.response.data) !== "undefined") {

                // Is validation error?
                const res = error.response.data
                if (res.validationErrors !== null) {
                    return;
                }

                // Is known error?
                if (res.result === "ERR_EXPIRE_TOKEN" || res.result === "ERR_INVALID_TOKEN" || res.result === "ERR_LOGIN_REQUIRE_BIRTHDAY") {
                    return;
                }
            }

            const mes = message ? message : "サーバーとの通信に失敗しました。しばらくしてから再度お試しください。";
            this.showToast(mes, true)
        } else {
            // JavaScript error
        }
    }

    /**********************************************************/
    // get error list
    /**********************************************************/
    static showToast (message, danger = false) {
        // Hide old toast
        const allToasts = document.getElementsByClassName("toastify");
        while (allToasts.length) {
            allToasts.item(0).remove()
        }

        // https://github.com/apvarun/toastify-js#readme
        Toastify({
            text: message,
            duration: 3000,
            newWindow: false,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            className: danger ? "toast-alert-danger" : "toast-alert",
            stopOnFocus: false,
            onClick: function(){} // Callback after click
        }).showToast();
    }
}
