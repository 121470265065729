<template>
    <BaseLayout>
      <router-view/>
    </BaseLayout>
</template>
<script>
import BaseLayout from './components/layouts/BaseLayout'

export default {
  components: {BaseLayout},
  methods: {}
}

</script>
<style lang="scss">
@import 'src/assets/scss/app.scss';
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch
}

#app {
}

</style>