import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Common from "jsadminclient/common";

Vue.use(Router)

const routes = [
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      title: 'ホーム'
    },
    // beforeEnter: routeGuard,
    component: () => import( /* webpackChunkName: "js/dashboard" */ './views/Dashboard.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'ログイン',
      goHome: true,
    },
    component: () => import( /* webpackChunkName: "js/login" */ './views/LoginWithDob.vue') // TODO: use Login.vue
  },
  {
    path: '/loginwithdob',
    name: 'LoginWithDob',
    meta: {
      title: 'ログイン',
      goHome: true,
    },
    component: () => import( /* webpackChunkName: "js/loginwithdob" */ './views/LoginWithDob.vue')
  },
  {
    path: '/firsttime',
    name: 'InitialLoginFirst',
    meta: {
      title: '初回設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/firsttime" */ './views/InitialLoginFirst.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    meta: {
      title: 'パスワードリセット'
    },
    component: () => import( /* webpackChunkName: "js/resetpassword" */ './views/ResetPassword.vue')
  },
  {
    path: '/resetpasswordemailsent',
    name: 'ResetPasswordEmailSent',
    meta: {
      title: 'パスワードリセット'
    },
    component: () => import( /* webpackChunkName: "js/resetpasswordemailsent" */ './views/ResetPasswordEmailSent.vue')
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    meta: {
      title: 'パスワード変更'
    },
    component: () => import( /* webpackChunkName: "js/changepassword" */ './views/ChangePassword.vue')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: 'ホーム',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/home" */ './views/Home.vue')
  },
  {
    path: '/tournamentinfo',
    name: 'TournamentInfo',
    meta: {
      title: 'ホーム',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/tournamentinfo" */ './views/TournamentInfo.vue')
  },
  {
    path: '/inputcondition',
    name: 'InputHealthCondition',
    meta: {
      title: '健康状態入力',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/inputcondition" */ './views/InputHealthCondition.vue')
  },
  {
    path: '/account',
    name: 'AccountSettings',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/account" */ './views/AccountSettings.vue')
  },
  {
    path: '/editpasswordrequired',
    name: 'EditPasswordRequired',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/editpasswordrequired" */ './views/EditPasswordRequired.vue')
  },
  {
    path: '/editnopasswordrequired',
    name: 'EditNoPasswordRequired',
    meta: {
      title: 'アカウント設定'
    },
    component: () => import( /* webpackChunkName: "js/editnopasswordrequired" */ './views/EditNoPasswordRequired.vue')
  },
  {
    path: '/editmedicalhistory',
    name: 'EditMedicalHistory',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editmedicalhistory" */ './views/EditMedicalHistory.vue')
  },
  {
    path: '/deleteaccount',
    name: 'DeleteAccount',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/deleteaccount" */ './views/DeleteAccount.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    meta: {
      title: 'プライバシーポリシー',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/privacy" */ './views/PrivacyPolicy.vue')
  },
  {
    path: '/cookie',
    name: 'CookiePolicy',
    meta: {
      title: 'Cookieポリシー',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/cookie" */ './views/CookiePolicy.vue')
  },
  {
    path: '/termsofservice',
    name: 'TermsOfService',
    meta: {
      title: '利用規約',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/termsofservice" */ './views/TermsOfService.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'お問い合わせ',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/Contact.vue')
  },
  {
    path: '/credit',
    name: 'Credit',
    meta: {
      title: 'HeaLoとは',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/credit" */ './views/Credit.vue')
  },
  {
    path: '/record',
    name: 'Record',
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/record" */ './views/Record.vue')
  },
  {
    path: '/record/:tournamentId/list/:userId',
    name: 'UserRecord',
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/user-record" */ './views/UserRecord.vue')
  },
  {
    path: '/record/:tournamentId/list/:userId/detail',
    name: 'UserRecordDetail',
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/user-record-detail" */ './views/UserRecordDetail.vue')
  },
  {
    path: '/touradminsip',
    name: 'TourAdminsIp',
    meta: {
      title: '大会管理者/関係者管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/touradminsip" */ './views/TourAdminsIp.vue')
  },
  {
    path: '/touradminsip/:tournamentId/register',
    name: 'TourAdminsIpRegistration',
    meta: {
      title: '大会管理者/関係者管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/touradminsip-user-registration" */ './views/TourAdminsIpRegistration.vue')
  },
  {
    path: '/touradminsip/:tournamentId/batch',
    name: 'TourAdminsIpBatch',
    meta: {
      title: '大会管理者/関係者管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/touradminsip-batch" */ './views/TourAdminsIpBatch.vue')
  },
  {
    path: '/touradminsip/:tournamentId/info/:userId',
    name: 'TourAdminsIpUserDetail',
    meta: {
      title: '大会管理者/関係者管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/touradminsip-user-detail" */ './views/TourAdminsIpUserDetail.vue')
  },
  {
    path: '/reminders',
    name: 'Reminders',
    meta: {
      title: 'リマインダー管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/reminders" */ './views/Reminders.vue')
  },
  {
    path: '/reminders/:tournamentId/register',
    name: 'ReminderRegistration',
    meta: {
      title: 'リマインダー管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/reminder-registration" */ './views/ReminderRegistration.vue')
  },
  {
    path: '/reminders/:tournamentId/info/:reminderId',
    name: 'ReminderDetail',
    meta: {
      title: 'リマインダー管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/reminder-detail" */ './views/ReminderDetail.vue')
  },
  {
    path: '/scanlogs',
    name: 'ScanLogs',
    meta: {
      title: '入場ログ参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/scanlogs" */ './views/ScanLogs.vue')
  },
  {
    path: '/scanlogs/:tournamentId/info/:scanLogId',
    name: 'ScanLogDetail',
    meta: {
      title: '入場ログ参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/scan-log-detail" */ './views/ScanLogDetail.vue')
  },
  {
    path: '/users',
    name: 'Users',
    props: true,
    meta: {
      title: 'ユーザー管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/users" */ './views/Users.vue')
  },
  {
    path: '/users/register',
    name: 'UserRegistration',
    props: true,
    meta: {
      title: 'ユーザー管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/user-registration" */ './views/UserRegistration.vue')
  },
  {
    path: '/users/batch',
    name: 'UserBatchRegistration',
    props: true,
    meta: {
      title: 'ユーザー管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/user-batch-registration" */ './views/UserBatchRegistration.vue')
  },
  {
    path: '/users/info/:userId',
    name: 'UserDetail',
    props: true,
    meta: {
      title: 'ユーザー管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/user-detail" */ './views/UserDetail.vue')
  },
  {
    path: '/tournaments',
    name: 'Tournaments',
    meta: {
      title: '大会管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/tournaments" */ './views/Tournaments.vue')
  },

  {
    path: '/tournaments/register',
    name: 'TournamentRegistration',
    props: true,
    meta: {
      title: '大会管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/tournament-registration" */ './views/TournamentRegistration.vue')
  },
  {
    path: '/tournaments/batch',
    name: 'TournamentBatchRegistration',
    props: true,
    meta: {
      title: '大会管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/tournament-batch-registration" */ './views/TournamentBatchRegistration.vue')
  },
  {
    path: '/tournaments/info/:tournamentId',
    name: 'TournamentDetail',
    props: true,
    meta: {
      title: '大会管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/tournament-detail" */ './views/TournamentDetail.vue')
  },
  {
    path: '/dblogs',
    name: 'DBLogs',
    meta: {
      title: 'ログ管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/dblogs" */ './views/DBLogs.vue')
  },
  {
    path: '/dblogs/info/:logId',
    name: 'DBLogDetail',
    meta: {
      title: 'ログ管理',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/db-log-detail" */ './views/DBLogDetail.vue')
  },
  {
    path: '/admissioncontrol',
    name: 'AdmissionControl',
    meta: {
      title: '入場管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/admissioncontrol" */ './views/AdmissionControl.vue')
  },
  {
    path: '/scan/:tournamentId',
    name: 'Scan',
    props: true,
    meta: {
      title: '入場管理',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/scan" */ './views/Scan.vue')
  },
  {
    path: '/healthrecord',
    name: 'HealthRecord',
    props: true,
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/admissioncontrol" */ './views/HealthRecord.vue')
  },
  {
    path: '/editname',
    name: 'EditName',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditName.vue')
  },
  {
    path: '/editbirthday',
    name: 'EditBirthday',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditBirthday.vue')
  },
  {
    path: '/editsex',
    name: 'EditSex',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditSex.vue')
  },
  {
    path: '/editcountry',
    name: 'EditCountry',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditCountry.vue')
  },
  {
    path: '/editmailaddress',
    name: 'EditMailAddress',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditMailAddress.vue')
  },
  {
    path: '/editaddress',
    name: 'EditAddress',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditAddress.vue')
  },
  {
    path: '/editphone',
    name: 'EditPhone',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/EditPhone.vue')
  },
  {
    path: '/vaccinechange',
    name: 'VaccineChange',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/contact" */ './views/VaccineChange.vue')
  },
  {
    path: '/pcrtestchange',
    name: 'PcrTestChange',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/PcrTestChange" */ './views/PcrTestChange.vue')
  },

];

const router = new Router({
  mode: 'history',
  routes: routes,
  // linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
});

router.beforeEach(async (to, from, next) => {
  const needLogin = to.matched.some(record => record.meta.requiresAuth)
  const config = store.getters['config']
  const invalidToken = (config.token === null || config.token === "undefined")

  // Check login
  if (needLogin && invalidToken) {
    next({name: 'Login'})
    return
  }

  const goHome = to.matched.some(record => record.meta.goHome)
  if (goHome && !invalidToken) {
    try {
      const user_id = config.userId
      if (user_id) {
        const api = Common.createUserApi(config.token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        if (jsonObject.requireUserInfo === false) {
          next({name: 'Home'})
          return
        }
      }
    } catch (error) {
    }
  }

  next()
})


export default router
