<template>
  <div :class="[classes, 'loader-' + type, loading ? 'loading' : 'loaded']" class="loader-container">
    <div class="loader-text"><span>{{ loaderText }}</span></div>
    <div v-if="type==='pulse'" class="loader">
      <span class="loader__dot pulse pulse__one"></span>
      <span class="loader__dot pulse pulse__two"></span>
      <span class="loader__dot pulse pulse__three"></span>
    </div>
    <div v-if="type==='spin'" class="loader spin">
      <span class="loader__dot"></span>
      <span class="loader__dot"></span>
      <span class="loader__dot"></span>
    </div>
    <div v-if="type==='slide'" class="loader slide">
      <span class="loader__dot slide__one"></span>
      <span class="loader__dot"></span>
      <span class="loader__dot"></span>
      <span class="loader__dot slide__two"></span>
    </div>
    <div v-if="type==='slide'" class="loader">
      <span class="loader__dot pulse__wave"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DotLoader",
  props: {
    classes: {type: String, default: 'm-t-25 m-b-25'},
    type: {type: String, default: 'pulse'},
    loading: {type: Boolean, default: true},
  },
  data() {
    return {
      loaderText: "待機中"
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  top: 20px;
  left: 35px;
  margin: 0 auto;

  &-container {
    width: 130px;
    margin: 0 auto;
    height: 65px;
  }

  &-text {
    text-align: center;
    color: #E0B1B7;
  }

  &-slide &-text {
    padding-left: 44px;
  }

  &__dot {
    display: block;
    width: 8px;
    height: 8px;
    margin: 6px;
    border-radius: 5px;
    background-color: #E0B1B7;
    float: left;
  }

  .pulse {
    will-change: transform, opacity;
    -webkit-animation: pulse 1.25s infinite ease;
    animation: pulse 1.25s infinite ease;

    &__one {
      -webkit-animation-delay: 250ms;
      animation-delay: 250ms;
    }

    &__two {
      -webkit-animation-delay: 500ms;
      animation-delay: 500ms;
    }

    &__three {
      -webkit-animation-delay: 750ms;
      animation-delay: 750ms;
    }

    &__wave {
      will-change: box-shadow;
      -webkit-animation: pulseWave 1.25s infinite ease;
      animation: pulseWave 1.25s infinite ease;
    }
  }

  .spin {
    will-change: transform;
    -webkit-animation: spin 1s infinite ease;
    animation: spin 1s infinite ease;
  }

  .slide {
    will-change: transform, opacity;
    -webkit-animation: slide 800ms infinite linear;
    animation: slide 800ms infinite linear;


    &__one {
      -webkit-animation: fadeIn 800ms infinite ease;
      animation: fadeIn 800ms infinite ease;
    }

    &__two {
      -webkit-animation: fadeOut 800ms infinite ease;
      animation: fadeOut 800ms infinite ease;
    }
  }

}


//.loader-container {
//  margin: 0;
//  display: flex;
//  justify-content: center;
//  flex-direction: row;
//  height: 100%;
//  align-items: center;
//  background-color: #fff;
//}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(18px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(18px);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@-webkit-keyframes pulseWave {
  25% {
    box-shadow: 0 0 0 0 rgba(85, 3, 3, 0.5);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(49, 1, 1, 0);
  }
}

@keyframes pulseWave {
  25% {
    box-shadow: 0 0 0 0 rgba(85, 3, 3, 0.5);
  }
  100% {
    box-shadow: 0 0 0 6px rgba(49, 1, 1, 0);
  }
}

</style>
