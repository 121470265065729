/* Constants */

// App detail
export const app = Object.freeze({
  alias: 'default-app',
  brand: '健康情報管理システム HeaLo(管理画面)'
})

// API Configuration
export const apiConfig = Object.freeze({
  basePath: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate, pre-check=0, post-check=0',
    'Pragma': 'no-cache',
    'Clear-Site-Data': '*',
    'X-Client-Key': process.env.VUE_APP_API_CLIENT_KEY,
    'X-API-Token': ''
  },
})

// API TODO: Remove once resolved for apiConfig
export const api = Object.freeze({
  basePath: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate, pre-check=0, post-check=0',
    'Pragma': 'no-cache',
    'Clear-Site-Data': '*',
    'X-Client-Key': process.env.VUE_APP_API_CLIENT_KEY,
    'X-API-Token': ''
  },
})

// Default setup
export const defaults = Object.freeze({
  adminType: 0,
  adminTypes: {
    0: 'すべて',
    1: 'JTA管理者',
    2: '大会管理者',
    3: 'なし',
  },
  accountStatus: 0,
  accountStatuses: {
    0: 'すべて', // All(Default)
    1: '有効', // Enabled
    2: '停止', // Disabled
  },
  tournamentStatuses: [
    {
      id: 0,
      name: 'すべて'
    },
    {
      id: 3,
      name: '終了'
    },
    {
      id: 2,
      name: '期間中'
    },
    {
      id: 1,
      name: '近日開始'
    },
  ],
  roleId: 0,
  tournamentId: 0,
  healthStatus: 0,
  healthStatuses: {
    0: 'すべて', // All(Default)
    1: '入場拒否', // Rejection
    2: '異常あり', // Abnormality
    3: '未入力', // No Input
    4: '大会承認済', // Acceptation
    5: '異常なし', // Normality
  },
  enterStatus: {
    0:  'NO INPUT',
    1: 'GOOD',
    2: 'BAD',
    reasons: {
      0: {
        'button': 'enter-status-normal',
        'icon': 'fas fa-address-card', // All(Default)
        'value': 'すべて'
      },
      1: {
        'button': 'enter-status-rejected',
        'icon': 'fas fa-times-circle', // Admission denied
        'value': '入場拒否'
      },
      2: {
        'button': 'enter-status-abnormal',
        'icon': 'fas fa-user-times', // Abnormality
        'value': '異常あり'
      },
      3: {
        'button': 'enter-status-empty',
        'icon': 'fas fa-question-circle', // No Input
        'value': '未入力'
      },
      4: {
        'button': 'enter-status-approved',
        'icon': 'far fa-calendar-check', // Approved
        'value': '大会承認済'
      },
      5: {
        'button': 'enter-status-normal',
        'icon': 'fas fa-user-check', // No abnormality
        'value': '異常なし'
      },
    }
  },
  alert: 0,
  alerts: {
    0: 'すべて',
    1: 'あり',
    2: 'なし',
  },

  coronaVaccinationCount: 0,
  hasCoronaVaccinations: {
    0: 'すべて',
    1: 'なし',
    2: '1回目',
    3: '2回目',
    4: '3回目',
    5: '4回目',
    6: '5回目',
    7: '6回目',
    8: '7回目',
    9: '8回目',
    10: '9回目',
    11: '10回目',
  },

  pcrTestResultStatus: 0,
  pcrTestResultStatuses: {
    0: 'すべて',
    1: '陽性',
    2: '陰性',
    3: '未検査',
  }
})

// Datatable
export const datatable = Object.freeze({
  default: {
    perPage: 20,
  },
  labels: {
    user_id: 'ユーザーID',
    userId: 'ユーザーID',
    userName: 'ユーザー名',
    user_name: 'ユーザー名',
    is_admin: '管理者',
    isAdmin: '管理者',
    password: 'パスワード',
    confirm_password: 'パスワード(確認)',
    confirmPassword: 'パスワード(確認)',
    os: 'OS',
    version: 'OSversion',
    device_model_name: '型番',
    deviceModelName: '型番',
    deviceModel: '型番',
  },
  columns: {
    records: [
      // {
      //     key: 'account_status',
      //     label: 'アカウントのステータス',
      // },
      {
        key: 'healthStatus',
        label: 'ステータス'
      },
      {
        key: 'lastName',
        label: '姓'
      },
      {
        key: 'firstName',
        label: '名'
      },
      {
        key: 'userRole',
        label: 'ロール',
        line:1,
        tooltip: false
      },
      {
        key: 'organization',
        label:'申告事項'
      },
      {
        key: 'userId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],

    record: [
      {
        key: 'healthStatus',
        label: 'ステータス'
      },
      {
        key: 'date',
        label: '日付'
      },
      {
        key: 'bodyTemp',
        label: '体温'
      },
      {
        key: 'alerts',
        label: 'アラート'
      },
      {
        key: 'remarks',
        label: '備考'
      },
      {
        key: 'heatStrokeScore',
        label: '熱中症点数'
      },
      {
        key: 'user_id',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    users: [
      {
        key: 'userId',
        label: '入場管理番号'
      },
      {
        key: 'lastName',
        label: '姓',
      },
      {
        key: 'firstName',
        label: '名'
      },
      {
        key: 'email',
        label: 'メールアドレス'
      },
      {
        key: 'isJTA',
        label: 'JTA管理者'
      },
      {
        key: 'isTournamentAdmin',
        label: '大会管理者'
      },
      {
        key: 'userId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    touradminsip: [
      {
        key: 'userId',
        label: '入場管理番号'
      },
      {
        key: 'lastName',
        label: '姓'
      },
      {
        key: 'firstName',
        label: '名'
      },
      {
        key: 'email',
        label: 'メールアドレス'
      },
      {
        key: 'userRoleName',
        label: 'ロール'
      },
      {
        key: 'organization',
        label: '申告事項'
      },
      {
        key: 'userId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    reminders: [
      {
        key: 'reminderTitle',
        label: 'タイトル'
      },
      {
        key: 'time',
        label: '時刻'
      },
      {
        key: 'sendTargetId',
        label: '送信先'
      },
      {
        key: 'subject',
        label: '件名'
      },
      {
        key: 'body',
        label: '本文'
      },
      {
        key: 'reminderId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    scanlogs: [
      {
        key: 'dateTime',
        label: '入場日時'
      },
      {
        key: 'lastName',
        label: '姓'
      },
      {
        key: 'firstName',
        label: '名'
      },
      {
        key: 'userId',
        label: '入場管理番号'
      },
      {
        key: 'scanLogId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    tournaments: [
      {
        key: 'status',
        label: 'ステータス'
      },
      {
        key: 'tournamentName',
        label: '大会名'
      },
      {
        key: 'startAt',
        label: '大会開始日'
      },
      {
        key: 'endAt',
        label: '大会終了日'
      },
      {
        key: 'tournamentId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
    dblogs: [
      {
        key: 'dateTime',
        label: '操作日時'
      },
      {
        key: 'operation',
        label: '操作'
      },
      {
        key: 'lastName',
        label: '姓'
      },
      {
        key: 'firstName',
        label: '名'
      },
      {
        key: 'userId',
        label: '入場管理番号'
      },
      {
        key: 'target',
        label: '内容'
      },
      {
        key: 'logId',
        label: '詳細',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
  }
})

// Sidebar menu
export const sidebar = Object.freeze({
  menu: [{
    name: '大会メニュー',
    id: 1,
    route: null,
    class: 'menu',
    submenu: [{
      name: 'ホーム',
      route: 'Home',
      state: 'default',
      icon: {
        default: 'ic_menu_home_off@2x.png',
        active: 'ic_menu_home_on@2x.png',
        hover: 'ic_menu_home_hover@2x.png',
      },
    }, {
      name: '健康記録参照',
      route: 'Record',
      state: 'default',
      icon: {
        default: 'ic_menu_record_off@2x.png',
        active: 'ic_menu_record_on@2x.png',
        hover: 'ic_menu_record_hover@2x.png',
      },
    },
      {
        name: '大会管理者/関係者管理',
        route: 'TourAdminsIp',
        state: 'default',
        icon: {
          default: 'ic_menu_tour_admin_off@2x.png',
          active: 'ic_menu_tour_admin_on@2x.png',
          hover: 'ic_menu_tour_admin_hover@2x.png',
        },
      },
      {
          name: 'リマインダー管理',
          route: 'Reminders',
          state: 'default',
          icon: {
              default: 'ic_menu_reminder_off@2x.png',
              active: 'ic_menu_reminder_on@2x.png',
              hover: 'ic_menu_reminder_hover@2x.png',
          },
      },
      {
          name: '入場ログ参照',
          route: 'ScanLogs',
          state: 'default',
          icon: {
              default: 'ic_menu_scan_logs_off@2x.png',
              active: 'ic_menu_scan_logs_on@2x.png',
              hover: 'ic_menu_scan_logs_hover@2x.png',
          },
      },
      {
        name: 'お問い合わせ',
        route: 'Contact',
        state: 'default',
        icon: {
          default: 'ic_menu_contact_off@2x.png',
          active: 'ic_menu_contact_on@2x.png',
          hover: 'ic_menu_contact_hover@2x.png',
        },
      }
    ]
  },
    {
      name: 'JTAメニュー',
      id: 2,
      route: null,
      submenu: [{
        name: 'ユーザー管理',
        route: 'Users',
        state: 'default',
        icon: {
          default: 'ic_menu_user_off@2x.png',
          active: 'ic_menu_user_on@2x.png',
          hover: 'ic_menu_user_hover@2x.png',
        },
      }, {
        name: '大会管理',
        route: 'Tournaments',
        state: 'default',
        icon: {
          default: 'ic_menu_tournament_off@2x.png',
          active: 'ic_menu_tournament_on@2x.png',
          hover: 'ic_menu_tournament_hover@2x.png',
        },
      }
        , {
            name: 'ログ管理',
            route: 'DBLogs',
            state: 'default',
            icon: {
                default: 'ic_menu_logs_off@2x.png',
                active: 'ic_menu_logs_on@2x.png',
                hover: 'ic_menu_logs_hover@2x.png',
            },
        }
      ]
    }
  ]
})

// Gender
export const gender = Object.freeze({
  1: '男性',
  2: '女性',
  3: 'その他'
})

// Roles
export const roles = Object.freeze({
  0: 'ゲスト',
  1: '管理者',
})

// Messages
export const messages = Object.freeze({
  'errors': {
    404: 'データが存在しません。',
  },
  'datatable': {
    'noData': 'データが存在しません。'
  },
})
