<template>
  <footer>
    <div class="copyright">
      <div class="container-fluid">
        <div class="row footer-div">
          <div class="col-12 footer-div-col">
            <div class="col-md-6">
              <div class="col-12 footer-menu">
                <div class="col-xs-6">
                  <div class="col-xs-3"><router-link class="" to="/privacy">プライバシー</router-link></div>
                  <div class="col-xs-3"><router-link :to="{ name: 'TermsOfService' }">利用規約</router-link></div>
                </div>
                <div class="col-xs-6">
                  <div class="col-xs-3"><router-link class="" to="/cookie">Cookie</router-link></div>
                  <div class="col-xs-3"><router-link :to="{ name: 'Contact' }">お問い合わせ</router-link></div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-12 footer-copyright">
                <div class="col-xs-6">
                  <i class="fas fa-globe"></i>
                  <select class="localize-dropdown ml-2">
                    <option>日本語</option>
                    <option>English</option>
                  </select>
                </div>
                <div class="col-xs-6">
                  <p class="copyright-text">©︎ 2021 JAPAN TENNIS ASSOCIATION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer"
}

</script>

<style scoped>

.footer-div {
  display: flex;
}

.footer-div-col {
  display: inherit;
}

.localize-dropdown {
  margin-left: 5px;
  padding: 0.275rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

a{
  text-decoration: none;
}

footer{
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #888888;
  z-index: 1;
  background-color: #FFFFFF;
}

.footer-menu, .footer-menu .col-xs-6 {
  display: flex;
  padding-right: 20px;
}

.footer-menu .col-xs-6 .col-xs-3:first-child {
  padding-left: 0;
}

.footer-menu .col-xs-6 .col-xs-3:last-child {
  padding-right: 0;
}

.footer-menu .col-xs-6 .col-xs-3 {
  padding-left: 10px;
  padding-right: 10px;
}

.footer-copyright .col-xs-6 {
  float: right !important;
}

.footer-copyright .col-xs-6:last-child {
  padding-right: 30px;
}

.footer-menu .col-xs-6:first-child {
  padding-top: 8px;
}

.footer-menu .col-xs-6:last-child {
  padding-top: 8px;
}

.copyright span, .copyright a {
  color: #878787;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.copyright-text {
  padding-top: 8px;
}

@media only screen and (max-width: 992px) {

  .footer-menu {
    padding-right: 0;
  }

  .footer-div-col {
    display: initial;
  }

  .footer-copyright .col-xs-6 {
    float: initial !important
  }

  .footer-copyright .col-xs-6:first-child {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .footer-copyright .col-xs-6:last-child {
    padding-top: 7px;
  }

}

@media only screen and (max-width: 768px) {

  .footer-menu {
    padding-right: 0;
  }

  .footer-menu .col-xs-6 {
    display: block;
  }

  .footer-menu .col-xs-6:first-child {
    padding-right: 38px;
    padding-top: 0;
  }

  .footer-menu .col-xs-6:last-child {
    padding-left: 38px;
    padding-top: 0;
  }

  .footer-menu .col-xs-6:first-child .col-xs-3, .footer-menu .col-xs-6:last-child .col-xs-3 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .footer-menu .col-xs-6 .col-xs-3 {
    padding-left: 0;
    padding-right: 0;
  }

}

@media only screen and (max-width: 376px) {

  .copyright-menu ul {
    margin: 0;
    font-size: 0.8rem;
    text-align: left;
  }

  footer {
    padding: 6px 0;
  }

  select .localize-dropdown {
    margin-left: -17px;
    margin-top: -23px;
  }

  select {
    width: 74px;
  }

}

</style>