<template>
  <div id="wrapper" :key="reloadIndex" class="wrapper">
    <div id="wrapper-sidebar" class="wrapper-sidebar sticky">
      <div class="wrapper-sidebar-header">
        <router-link class="navbar-brand" to="/home">
          <img alt="" src="../../assets/images/sidebar/jta_logo.png">
        </router-link>
        <p class="logo-title">健康情報管理システム<br>HeaLo</p>

        <button id="wrapper-sidebar-menu-toggle" class="btn btn-default menu wrapper-sidebar-menu-toggle"
                @click="menuToggle">
          <img alt="" src="../../assets/images/sidebar/list.svg">
        </button>
      </div>
      <ul id="nav-wrap" class="nav flex-column flex-nowrap overflow-hidden">
        <li v-for="(menu, index) in sidebar.menu" :key="'menu-item-'+index" :class="{
                    active: $route.name === menu.route ||
                        ('submenu' in menu && menu.submenu.some(x => x.route === $route.name)) ||
                        ('from' in $route.params && $route.params.from === menu.route) ||
                        (from === menu.route),
                    'nav-item': !('submenu' in menu),
                    [menu.class]: ('class' in menu)
                }" class="nav-menu">
          <template v-if="!isJTA && menu.id === 2">
          </template>
          <template v-else>
            <router-link v-if="menu.route" :to="{name: menu.route}" @click="menuToggle()">
              <span class="m-l-30 font-weight-medium font-12 text-clamp-1">{{ menu.name }}</span>
            </router-link>
            <span v-else class="m-l-30 font-weight-medium font-12 text-clamp-1">{{ menu.name }}</span>
          </template>
          <ul v-if="'submenu' in menu" class="nav-submenu-wrap">
            <li v-for="(submenu, subIndex) in menu.submenu"
                :key="'submenu-item-'+index+subIndex"
                :class="{
                active: $route.name === submenu.route,
                'nav-item': !('submenu' in submenu),
                [menu.class]: ('class' in submenu)
              }"
                @mouseenter="setMenuHoverState(index, subIndex)"
                @mouseleave="preventMenuState(index, subIndex)">
              <template v-if="!isJTA && menu.id === 2">
              </template>
              <template v-else>
                <router-link :style="{ 'background-image' :  'url(' + getIconUrl(index, subIndex) + ')'}"
                             :to="{name: submenu.route}"
                             class="nav-link">
                  <h5>{{ submenu.name }}</h5>
                </router-link>
              </template>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {app, sidebar} from '@/constants'
import Common from 'jsadminclient/common'
import {mapGetters} from 'vuex'

export default {
  name: 'SideBar',
  data() {
    return {
      params: app.alias + '-params',
      from: '',
      sidebar: sidebar,
      reloadIndex: 1,
      isJTA: false,
      lastWindowWidth: 0,
    }
  },
  created() {
    window.addEventListener("resize", this.windowResizeHandler)
  },
  mounted() {
    this.lastWindowWidth = window.innerWidth
    if (this.lastWindowWidth <= this.getMenuExpandWidth()) {
      $('#wrapper, .content').addClass('toggled')
    } else {
      $("#wrapper, .content").removeClass("toggled")
    }
    this.getUser()
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResizeHandler)
  },
  computed: {
    ...mapGetters(['config']),
  },
  watch: {
    $route(to, from) {
      this.toggleContent()
    }
  },
  methods: {
    getIconUrl(index, subIndex) {
      const state = this.getMenuState(index, subIndex)
      const icon = this.sidebar.menu[index].submenu[subIndex].icon[state]
      return require('../../assets/images/sidebar/' + icon)
    },
    setMenuHoverState(index, subIndex) {
      this.sidebar.menu[index].submenu[subIndex].state = 'hover'
    },
    preventMenuState(index, subIndex) {
      this.sidebar.menu[index].submenu[subIndex].state = this.getMenuState(index, subIndex)
    },
    getMenuState(index, subIndex) {
      return this.$route.name === this.sidebar.menu[index].submenu[subIndex].route ? 'active' : 'default'
    },
    menuToggle() {
      $('#wrapper, .content').toggleClass('toggled')
    },
    toggleContent() {
      if ($('#wrapper').hasClass('toggled')) {
        $('.content').addClass('toggled')
      }
    },
    getMenuExpandWidth() {
      return 992
    },
    windowResizeHandler(e) {
      const thresholdPx = this.getMenuExpandWidth()
      const currentWidth = window.innerWidth

      // Large to medium
      // console.log("lastWindowWidth=" + this.lastWindowWidth + " thresholdPx=" + thresholdPx)
      if (currentWidth <= thresholdPx && this.lastWindowWidth > thresholdPx) {
        $('#wrapper, .content').addClass('toggled')
      }
      // Medium to large
      else if (currentWidth > thresholdPx && this.lastWindowWidth <= thresholdPx) {
        $("#wrapper, .content").removeClass("toggled")
      }

      this.lastWindowWidth = currentWidth
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.isJTA = jsonObject.isJTA
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.nav {
  &-item {
    height: 48px;
    padding-left: 7px;
  }

  &-link {
    height: 100%;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 20px 15px;

    h5 {
      font-size: 12px;
    }
  }
}

ul {
  padding-inline-start: 0;

  li {
    a {
      color: #171717;
      text-decoration: none;

    }

    &.nav-item {
      > a {
        h4, h5 {
          color: #333333;
          font-size: 14px;
        }
      }
    }

    .nav-item:hover {
      h5 {
        color: #E0B1B7;
      }

    }

    a.router-link-active.router-link-exact-active.nav-link {
      color: #C95D6C;
    }

    ul > li.active h5 {
      color: #C95D6C;
    }

    h4 {
      font-weight: bold;
    }

    h4, h5 {
      margin-bottom: 0 !important;
      line-height: 1;
      padding: 10px 8px 8px 20px;
    }
  }

  ul {
    li {
      h5 {
        padding-left: 40px;
      }
    }
  }

  > li {
    margin: 8px 0 4px 0;
    padding: 0;
    display: block;

    ul > li {
      margin: 2px 0 1px 0;
    }

    &.bottom-fixed {
      position: absolute;
      width: 100%;
      bottom: 50px;
    }
  }
}

img {
  width: 58px;
  height: auto;
}

.submenu-icon {
  padding: 1px 7px;
  width: 34px;
  height: auto;
}

.wrapper {
  padding-left: 0;
  width: 260px;
  border-right: 2px solid #F2ECF0;

  &-sidebar {
    z-index: 1000;
    position: relative;
    width: inherit;
    height: 100vh;
    min-height: 100%;
    background: #F2ECF0;
    overflow: hidden;

    &.sticky {
      position: fixed;
      width: inherit;
      top: 0;
      left: 0;
      transition-duration: 0.01s;
    }

    &-header {
      display: inline-flex;
    }

    &-menu-toggle {
      color: #888888;
      padding: 0;
      position: absolute;
      top: 5px;
      right: 30px;
      border: none !important;

      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }

      img {
        width: 21px;
        height: auto;
      }
    }
  }

  &.toggled {
    width: 80px;

    .navbar-brand {
      display: none;
    }

    p, a img, h5, span {
      opacity: 0;
    }
  }

  .logo-title {
    font-weight: bold;
    color: #002947;
    padding-top: 14px;
    margin-left: -1px;
    font-size: 10px;
    line-height: 13px;
    white-space: nowrap;
    word-break: keep-all;
  }

  .navbar-brand {
    margin-right: 0.55rem !important;
    margin-left: 0.25rem !important;

    img {
      width: auto !important;
      height: 44px !important;
      margin-left: 16px;
      margin-top: 0.5px;
    }
  }
}

#nav-wrap {
  margin-top: 10px;
}

.cursor-default {
  cursor: default !important;
}
</style>

