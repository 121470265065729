require('./bootstrap')

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VTooltip from 'v-tooltip'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueTimepicker from 'vue2-timepicker'

import {api} from './constants'

window.AdminApi = require('./assets/jsadminclient')
// window.AdminApi = AdminApi
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VTooltip)
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

Vue.config.productionTip = false

window.Apv = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
