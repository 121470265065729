window._ = require('lodash')

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
//
try {
    window.Popper = require('popper.js').default
    window.$ = window.jQuery = require('jquery')

    require('bootstrap')
} catch (exceptions) {
    console.log(exceptions)
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')
window.moment = require('moment-timezone')
moment.locale('ja')
window.math = require('mathjs')
window.$ = window.jQuery = require('jquery')

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// window.axios.defaults.headers.common['X-Client-Key'] = process.env.VUE_CLIENT_KEY
// window.axios.defaults.headers.common['X-API-Token'] = localStorage.getItem('default-app-user') ? JSON.parse(localStorage.getItem('default-app-user')).apiToken : null

