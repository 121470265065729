/**
 * Web API
 * API Version 1.0.0
 */
export class ApiConfig {
    constructor (basepath = "http://localhost/api/admin", headers = {}) {
        /**
         * エンドポイント
         * @type {string}
         */
        this.basepath = basepath;

        /**
         * 追加のヘッダ
         * @type {Object}
         */
        this.headers = headers;
    }
}


export class JTADownloadApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * jtaDownloadRecordsTournamentIdGetの結果コールバック関数
     *
     * @callback jtaDownloadRecordsTournamentIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?binary } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament CSV
     * @param { number } tournamentId Tournament ID
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { jtaDownloadRecordsTournamentIdGetCallback } callback コールバック
     */
    jtaDownloadRecordsTournamentIdGet (tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (healthStatus === void 0) { healthStatus = null; }
        if (role === void 0) { role = null; }
        if (coronaVaccinationCount === void 0) { coronaVaccinationCount = null; }
        if (latestPcrTestResult === void 0) { latestPcrTestResult = null; }
        if (alert === void 0) { alert = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/jta/download/records/{tournamentId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "healthStatus": healthStatus,
            "role": role,
            "coronaVaccinationCount": coronaVaccinationCount,
            "latestPcrTestResult": latestPcrTestResult,
            "alert": alert,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament CSV
     * @param { number } tournamentId Tournament ID
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<binary> }
     */
    async jtaDownloadRecordsTournamentIdGetAsync(tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.jtaDownloadRecordsTournamentIdGet(tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class JTALogApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * jtaLogsGetの結果コールバック関数
     *
     * @callback jtaLogsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTALogItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of log.
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time
     * @param { ?string } endTime End time
     * @param { ?string } keyword Search word
     * @param { ?number } operation Operation * 1: created * 2: updated * 3: deleted 
     * @param { ?string } sort Sort order * dateTime * operation * firstName * lastName             * userId * target 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { jtaLogsGetCallback } callback コールバック
     */
    jtaLogsGet (startAt, endAt, startTime, endTime, keyword, operation, sort, order, page, count, callback) {
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (startTime === void 0) { startTime = null; }
        if (endTime === void 0) { endTime = null; }
        if (keyword === void 0) { keyword = null; }
        if (operation === void 0) { operation = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/jta/logs";

        var query = {
            "startAt": startAt,
            "endAt": endAt,
            "startTime": startTime,
            "endTime": endTime,
            "keyword": keyword,
            "operation": operation,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of log.
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time
     * @param { ?string } endTime End time
     * @param { ?string } keyword Search word
     * @param { ?number } operation Operation * 1: created * 2: updated * 3: deleted 
     * @param { ?string } sort Sort order * dateTime * operation * firstName * lastName             * userId * target 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminJTALogItems> }
     */
    async jtaLogsGetAsync(startAt, endAt, startTime, endTime, keyword, operation, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.jtaLogsGet(startAt, endAt, startTime, endTime, keyword, operation, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaLogsLogIdGetの結果コールバック関数
     *
     * @callback jtaLogsLogIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTALogDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get log.
     * @param { number } logId Log ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time (HH:mm)
     * @param { ?string } endTime End time (HH:mm)
     * @param { ?string } keyword Search word
     * @param { ?number } operation Operation * 0: All(Default) / すべて * 1: created * 2: updated * 3: deleted 
     * @param { ?string } sort Sort order * dateTime * operation * firstName * lastName             * userId * target 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { jtaLogsLogIdGetCallback } callback コールバック
     */
    jtaLogsLogIdGet (logId, startAt, endAt, startTime, endTime, keyword, operation, sort, order, callback) {
        if (logId === void 0) { logId = null; }
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (startTime === void 0) { startTime = null; }
        if (endTime === void 0) { endTime = null; }
        if (keyword === void 0) { keyword = null; }
        if (operation === void 0) { operation = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/jta/logs/{logId}"
                .replace('{' + 'logId' + '}', encodeURIComponent(String(logId)));

        var query = {
            "startAt": startAt,
            "endAt": endAt,
            "startTime": startTime,
            "endTime": endTime,
            "keyword": keyword,
            "operation": operation,
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get log.
     * @param { number } logId Log ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time (HH:mm)
     * @param { ?string } endTime End time (HH:mm)
     * @param { ?string } keyword Search word
     * @param { ?number } operation Operation * 0: All(Default) / すべて * 1: created * 2: updated * 3: deleted 
     * @param { ?string } sort Sort order * dateTime * operation * firstName * lastName             * userId * target 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminJTALogDetail> }
     */
    async jtaLogsLogIdGetAsync(logId, startAt, endAt, startTime, endTime, keyword, operation, sort, order) {
        return new Promise((resolve, reject) => {
            this.jtaLogsLogIdGet(logId, startAt, endAt, startTime, endTime, keyword, operation, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class JTATournamentApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * jtaTournamentsGetの結果コールバック関数
     *
     * @callback jtaTournamentsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTATournamentItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of tournament.
     * @param { ?number } tournamentStatus Tournament status * Coming soon / 近日開始 &#x3D; 1 * During the duration / 期間中 &#x3D; 2 * Ended / 終了 &#x3D; 3 
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * tournamentStatus * tournamentName * startAt * endAt 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { jtaTournamentsGetCallback } callback コールバック
     */
    jtaTournamentsGet (tournamentStatus, startAt, endAt, keyword, sort, order, page, count, callback) {
        if (tournamentStatus === void 0) { tournamentStatus = null; }
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/jta/tournaments";

        var query = {
            "tournamentStatus": tournamentStatus,
            "startAt": startAt,
            "endAt": endAt,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of tournament.
     * @param { ?number } tournamentStatus Tournament status * Coming soon / 近日開始 &#x3D; 1 * During the duration / 期間中 &#x3D; 2 * Ended / 終了 &#x3D; 3 
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * tournamentStatus * tournamentName * startAt * endAt 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminJTATournamentItems> }
     */
    async jtaTournamentsGetAsync(tournamentStatus, startAt, endAt, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsGet(tournamentStatus, startAt, endAt, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaTournamentsPostの結果コールバック関数
     *
     * @callback jtaTournamentsPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Create tournament.
     * @param { SetAdminJTATournament } item Request data
     * @param { jtaTournamentsPostCallback } callback コールバック
     */
    jtaTournamentsPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/jta/tournaments";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Create tournament.
     * @param { SetAdminJTATournament } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaTournamentsPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaTournamentsTournamentIdDeleteの結果コールバック関数
     *
     * @callback jtaTournamentsTournamentIdDeleteCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete tournament.
     * @param { number } tournamentId Tournament ID
     * @param { jtaTournamentsTournamentIdDeleteCallback } callback コールバック
     */
    jtaTournamentsTournamentIdDelete (tournamentId, callback) {
        if (tournamentId === void 0) { tournamentId = null; }

        var url = this._config.basepath + "/jta/tournaments/{tournamentId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "delete",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete tournament.
     * @param { number } tournamentId Tournament ID
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaTournamentsTournamentIdDeleteAsync(tournamentId) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsTournamentIdDelete(tournamentId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaTournamentsTournamentIdGetの結果コールバック関数
     *
     * @callback jtaTournamentsTournamentIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTATournamentItem } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } tournamentStatus Tournament status * Coming soon / 近日開始 &#x3D; 1 * During the duration / 期間中 &#x3D; 2 * Ended / 終了 &#x3D; 3 
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * status * tournamentName * startAt * endAt 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { jtaTournamentsTournamentIdGetCallback } callback コールバック
     */
    jtaTournamentsTournamentIdGet (tournamentId, tournamentStatus, startAt, endAt, keyword, sort, order, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (tournamentStatus === void 0) { tournamentStatus = null; }
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/jta/tournaments/{tournamentId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "tournamentStatus": tournamentStatus,
            "startAt": startAt,
            "endAt": endAt,
            "keyword": keyword,
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } tournamentStatus Tournament status * Coming soon / 近日開始 &#x3D; 1 * During the duration / 期間中 &#x3D; 2 * Ended / 終了 &#x3D; 3 
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * status * tournamentName * startAt * endAt 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminJTATournamentItem> }
     */
    async jtaTournamentsTournamentIdGetAsync(tournamentId, tournamentStatus, startAt, endAt, keyword, sort, order) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsTournamentIdGet(tournamentId, tournamentStatus, startAt, endAt, keyword, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaTournamentsTournamentIdPostの結果コールバック関数
     *
     * @callback jtaTournamentsTournamentIdPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update tournament.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminJTATournament } item Request data
     * @param { jtaTournamentsTournamentIdPostCallback } callback コールバック
     */
    jtaTournamentsTournamentIdPost (tournamentId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/jta/tournaments/{tournamentId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update tournament.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminJTATournament } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaTournamentsTournamentIdPostAsync(tournamentId, item) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsTournamentIdPost(tournamentId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaTournamentsUploadPostの結果コールバック関数
     *
     * @callback jtaTournamentsUploadPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUploadResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Upload the excel data of tournament.
     * @param { file } file Excel file
     * @param { jtaTournamentsUploadPostCallback } callback コールバック
     */
    jtaTournamentsUploadPost (file, callback) {
        if (file === void 0) { file = null; }

        var url = this._config.basepath + "/jta/tournaments/upload";

        var query = {
        };
        var request_data = {};
        var form_data = new FormData();
        form_data.append('file', file);
        request_data = form_data;

        var headers = {
            "Content-Type": "multipart/form-data"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Upload the excel data of tournament.
     * @param { file } file Excel file
     * @returns { Promise.<AdminUploadResult> }
     */
    async jtaTournamentsUploadPostAsync(file) {
        return new Promise((resolve, reject) => {
            this.jtaTournamentsUploadPost(file, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class JTAUserApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * jtaUsersGetの結果コールバック関数
     *
     * @callback jtaUsersGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTAUserItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of users.
     * @param { ?number } adminType Admin Type * 0: All(Default) / すべて * 1: JTA admin / JTA管理者 * 2: Tournament admin / 大会管理者 * 3: Not admin / なし 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * isJTA * isTournamentAdmin 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { jtaUsersGetCallback } callback コールバック
     */
    jtaUsersGet (adminType, accountStatus, keyword, sort, order, page, count, callback) {
        if (adminType === void 0) { adminType = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/jta/users";

        var query = {
            "adminType": adminType,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of users.
     * @param { ?number } adminType Admin Type * 0: All(Default) / すべて * 1: JTA admin / JTA管理者 * 2: Tournament admin / 大会管理者 * 3: Not admin / なし 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * isJTA * isTournamentAdmin 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminJTAUserItems> }
     */
    async jtaUsersGetAsync(adminType, accountStatus, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.jtaUsersGet(adminType, accountStatus, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersPostの結果コールバック関数
     *
     * @callback jtaUsersPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Create user.
     * @param { SetAdminJTAUser } item Request data
     * @param { jtaUsersPostCallback } callback コールバック
     */
    jtaUsersPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/jta/users";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Create user.
     * @param { SetAdminJTAUser } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaUsersPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.jtaUsersPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUploadPostの結果コールバック関数
     *
     * @callback jtaUsersUploadPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUploadResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Upload the excel data of user.
     * @param { file } file Excel file
     * @param { jtaUsersUploadPostCallback } callback コールバック
     */
    jtaUsersUploadPost (file, callback) {
        if (file === void 0) { file = null; }

        var url = this._config.basepath + "/jta/users/upload";

        var query = {
        };
        var request_data = {};
        var form_data = new FormData();
        form_data.append('file', file);
        request_data = form_data;

        var headers = {
            "Content-Type": "multipart/form-data"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Upload the excel data of user.
     * @param { file } file Excel file
     * @returns { Promise.<AdminUploadResult> }
     */
    async jtaUsersUploadPostAsync(file) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUploadPost(file, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUserIdChangeStatusPostの結果コールバック関数
     *
     * @callback jtaUsersUserIdChangeStatusPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Change the status by the JTA administrator.
     * @param { string } userId User identification key
     * @param { SetAdminJTAUserChangeStatus } item Request data
     * @param { jtaUsersUserIdChangeStatusPostCallback } callback コールバック
     */
    jtaUsersUserIdChangeStatusPost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/jta/users/{userId}/changeStatus"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Change the status by the JTA administrator.
     * @param { string } userId User identification key
     * @param { SetAdminJTAUserChangeStatus } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaUsersUserIdChangeStatusPostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUserIdChangeStatusPost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUserIdDeleteの結果コールバック関数
     *
     * @callback jtaUsersUserIdDeleteCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete user.
     * @param { string } userId User identification key
     * @param { jtaUsersUserIdDeleteCallback } callback コールバック
     */
    jtaUsersUserIdDelete (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/jta/users/{userId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "delete",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete user.
     * @param { string } userId User identification key
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaUsersUserIdDeleteAsync(userId) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUserIdDelete(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUserIdGetの結果コールバック関数
     *
     * @callback jtaUsersUserIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminJTAUserItem } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get user.
     * @param { string } userId User identification key
     * @param { ?number } adminType Admin Type * 0: All(Default) / すべて * 1: JTA admin / JTA管理者 * 2: Tournament admin / 大会管理者 * 3: Not admin / なし 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * isJTA * isTournamentAdmin 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } single Get only one of the data. * 0: The data before and after is also acquired. * 1: Gets only the specified data. 
     * @param { jtaUsersUserIdGetCallback } callback コールバック
     */
    jtaUsersUserIdGet (userId, adminType, accountStatus, keyword, sort, order, single, callback) {
        if (userId === void 0) { userId = null; }
        if (adminType === void 0) { adminType = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (single === void 0) { single = null; }

        var url = this._config.basepath + "/jta/users/{userId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "adminType": adminType,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "single": single
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get user.
     * @param { string } userId User identification key
     * @param { ?number } adminType Admin Type * 0: All(Default) / すべて * 1: JTA admin / JTA管理者 * 2: Tournament admin / 大会管理者 * 3: Not admin / なし 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * isJTA * isTournamentAdmin 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } single Get only one of the data. * 0: The data before and after is also acquired. * 1: Gets only the specified data. 
     * @returns { Promise.<AdminJTAUserItem> }
     */
    async jtaUsersUserIdGetAsync(userId, adminType, accountStatus, keyword, sort, order, single) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUserIdGet(userId, adminType, accountStatus, keyword, sort, order, single, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUserIdPostの結果コールバック関数
     *
     * @callback jtaUsersUserIdPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update user.
     * @param { string } userId User identification key
     * @param { SetAdminUpdateJTAUser } item Request data
     * @param { jtaUsersUserIdPostCallback } callback コールバック
     */
    jtaUsersUserIdPost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/jta/users/{userId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update user.
     * @param { string } userId User identification key
     * @param { SetAdminUpdateJTAUser } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaUsersUserIdPostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUserIdPost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * jtaUsersUserIdResetPasswordPostの結果コールバック関数
     *
     * @callback jtaUsersUserIdResetPasswordPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Reset the password by the JTA administrator.
     * @param { string } userId User identification key
     * @param { jtaUsersUserIdResetPasswordPostCallback } callback コールバック
     */
    jtaUsersUserIdResetPasswordPost (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/jta/users/{userId}/resetPassword"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Reset the password by the JTA administrator.
     * @param { string } userId User identification key
     * @returns { Promise.<AdminEmptyResult> }
     */
    async jtaUsersUserIdResetPasswordPostAsync(userId) {
        return new Promise((resolve, reject) => {
            this.jtaUsersUserIdResetPasswordPost(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class LoginApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * loginPostの結果コールバック関数
     *
     * @callback loginPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminAuthenticated } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Log in with your account
     * @param { AdminCredentials } item Set request parameters to login
     * @param { loginPostCallback } callback コールバック
     */
    loginPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Log in with your account
     * @param { AdminCredentials } item Set request parameters to login
     * @returns { Promise.<AdminAuthenticated> }
     */
    async loginPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginResetPasswordFinishPostの結果コールバック関数
     *
     * @callback loginResetPasswordFinishPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Password reset completion process
     * @param { SetAdminResetPasswordFinish } item Set request parameters to reset
     * @param { loginResetPasswordFinishPostCallback } callback コールバック
     */
    loginResetPasswordFinishPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login/resetPasswordFinish";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Password reset completion process
     * @param { SetAdminResetPasswordFinish } item Set request parameters to reset
     * @returns { Promise.<AdminEmptyResult> }
     */
    async loginResetPasswordFinishPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginResetPasswordFinishPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginResetPasswordPostの結果コールバック関数
     *
     * @callback loginResetPasswordPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Reset password
     * @param { SetAdminResetPassword } item Set request parameters to reset
     * @param { loginResetPasswordPostCallback } callback コールバック
     */
    loginResetPasswordPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login/resetPassword";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Reset password
     * @param { SetAdminResetPassword } item Set request parameters to reset
     * @returns { Promise.<AdminEmptyResult> }
     */
    async loginResetPasswordPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginResetPasswordPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class SystemApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * systemNationalitiesGetの結果コールバック関数
     *
     * @callback systemNationalitiesGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminNationalityItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get nationalities
     * @param { systemNationalitiesGetCallback } callback コールバック
     */
    systemNationalitiesGet (callback) {

        var url = this._config.basepath + "/system/nationalities";

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get nationalities
     * @returns { Promise.<AdminNationalityItems> }
     */
    async systemNationalitiesGetAsync() {
        return new Promise((resolve, reject) => {
            this.systemNationalitiesGet((error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * systemPrefecturesGetの結果コールバック関数
     *
     * @callback systemPrefecturesGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminPrefectureItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get prefectures
     * @param { systemPrefecturesGetCallback } callback コールバック
     */
    systemPrefecturesGet (callback) {

        var url = this._config.basepath + "/system/prefectures";

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get prefectures
     * @returns { Promise.<AdminPrefectureItems> }
     */
    async systemPrefecturesGetAsync() {
        return new Promise((resolve, reject) => {
            this.systemPrefecturesGet((error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * systemRolesGetの結果コールバック関数
     *
     * @callback systemRolesGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminRoleItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get roles
     * @param { systemRolesGetCallback } callback コールバック
     */
    systemRolesGet (callback) {

        var url = this._config.basepath + "/system/roles";

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get roles
     * @returns { Promise.<AdminRoleItems> }
     */
    async systemRolesGetAsync() {
        return new Promise((resolve, reject) => {
            this.systemRolesGet((error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class TournamentApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * tournamentsHomeGetの結果コールバック関数
     *
     * @callback tournamentsHomeGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminTournamentSummaries } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament home info
     * @param { ?date } displayStartAt Display start date
     * @param { ?date } displayEndAt Display end date
     * @param { tournamentsHomeGetCallback } callback コールバック
     */
    tournamentsHomeGet (displayStartAt, displayEndAt, callback) {
        if (displayStartAt === void 0) { displayStartAt = null; }
        if (displayEndAt === void 0) { displayEndAt = null; }

        var url = this._config.basepath + "/tournaments/home";

        var query = {
            "displayStartAt": displayStartAt,
            "displayEndAt": displayEndAt
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament home info
     * @param { ?date } displayStartAt Display start date
     * @param { ?date } displayEndAt Display end date
     * @returns { Promise.<AdminTournamentSummaries> }
     */
    async tournamentsHomeGetAsync(displayStartAt, displayEndAt) {
        return new Promise((resolve, reject) => {
            this.tournamentsHomeGet(displayStartAt, displayEndAt, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsListGetの結果コールバック関数
     *
     * @callback tournamentsListGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminTournamentItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament list
     * @param { ?date } displayStartAt Display start date
     * @param { ?date } displayEndAt Display end date
     * @param { tournamentsListGetCallback } callback コールバック
     */
    tournamentsListGet (displayStartAt, displayEndAt, callback) {
        if (displayStartAt === void 0) { displayStartAt = null; }
        if (displayEndAt === void 0) { displayEndAt = null; }

        var url = this._config.basepath + "/tournaments/list";

        var query = {
            "displayStartAt": displayStartAt,
            "displayEndAt": displayEndAt
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament list
     * @param { ?date } displayStartAt Display start date
     * @param { ?date } displayEndAt Display end date
     * @returns { Promise.<AdminTournamentItems> }
     */
    async tournamentsListGetAsync(displayStartAt, displayEndAt) {
        return new Promise((resolve, reject) => {
            this.tournamentsListGet(displayStartAt, displayEndAt, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsListTournamentIdGetの結果コールバック関数
     *
     * @callback tournamentsListTournamentIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminTournamentItem } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament item
     * @param { number } tournamentId Tournament ID
     * @param { tournamentsListTournamentIdGetCallback } callback コールバック
     */
    tournamentsListTournamentIdGet (tournamentId, callback) {
        if (tournamentId === void 0) { tournamentId = null; }

        var url = this._config.basepath + "/tournaments/list/{tournamentId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament item
     * @param { number } tournamentId Tournament ID
     * @returns { Promise.<AdminTournamentItem> }
     */
    async tournamentsListTournamentIdGetAsync(tournamentId) {
        return new Promise((resolve, reject) => {
            this.tournamentsListTournamentIdGet(tournamentId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminIPUserItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of tournament involved people.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } role Role ID
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * userRoleName * organization 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { tournamentsTournamentIdIpUsersGetCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersGet (tournamentId, role, accountStatus, keyword, sort, order, page, count, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (role === void 0) { role = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "role": role,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of tournament involved people.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } role Role ID
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * userRoleName * organization 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminIPUserItems> }
     */
    async tournamentsTournamentIdIpUsersGetAsync(tournamentId, role, accountStatus, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersGet(tournamentId, role, accountStatus, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersIpUserIdDeleteの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersIpUserIdDeleteCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @param { tournamentsTournamentIdIpUsersIpUserIdDeleteCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersIpUserIdDelete (tournamentId, ipUserId, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (ipUserId === void 0) { ipUserId = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers/{ipUserId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'ipUserId' + '}', encodeURIComponent(String(ipUserId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "delete",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdIpUsersIpUserIdDeleteAsync(tournamentId, ipUserId) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersIpUserIdDelete(tournamentId, ipUserId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersIpUserIdGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersIpUserIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminIPUserItem } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @param { ?number } role Role ID
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * userRole 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { tournamentsTournamentIdIpUsersIpUserIdGetCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersIpUserIdGet (tournamentId, ipUserId, role, accountStatus, keyword, sort, order, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (ipUserId === void 0) { ipUserId = null; }
        if (role === void 0) { role = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers/{ipUserId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'ipUserId' + '}', encodeURIComponent(String(ipUserId)));

        var query = {
            "role": role,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @param { ?number } role Role ID
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * userId * firstName * lastName * accountStatus * email * userRole 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminIPUserItem> }
     */
    async tournamentsTournamentIdIpUsersIpUserIdGetAsync(tournamentId, ipUserId, role, accountStatus, keyword, sort, order) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersIpUserIdGet(tournamentId, ipUserId, role, accountStatus, keyword, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersIpUserIdPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersIpUserIdPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @param { SetAdminUpdateIpUser } item Request data
     * @param { tournamentsTournamentIdIpUsersIpUserIdPostCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersIpUserIdPost (tournamentId, ipUserId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (ipUserId === void 0) { ipUserId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers/{ipUserId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'ipUserId' + '}', encodeURIComponent(String(ipUserId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update involved people.
     * @param { number } tournamentId Tournament ID
     * @param { string } ipUserId Involved people ID
     * @param { SetAdminUpdateIpUser } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdIpUsersIpUserIdPostAsync(tournamentId, ipUserId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersIpUserIdPost(tournamentId, ipUserId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Create involved people.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminIpUser } item Request data
     * @param { tournamentsTournamentIdIpUsersPostCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersPost (tournamentId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Create involved people.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminIpUser } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdIpUsersPostAsync(tournamentId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersPost(tournamentId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdIpUsersUploadPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdIpUsersUploadPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUploadResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Upload the excel data of involved people.
     * @param { number } tournamentId Tournament ID
     * @param { file } file Excel file
     * @param { tournamentsTournamentIdIpUsersUploadPostCallback } callback コールバック
     */
    tournamentsTournamentIdIpUsersUploadPost (tournamentId, file, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (file === void 0) { file = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/ipUsers/upload"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        var form_data = new FormData();
        form_data.append('file', file);
        request_data = form_data;

        var headers = {
            "Content-Type": "multipart/form-data"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Upload the excel data of involved people.
     * @param { number } tournamentId Tournament ID
     * @param { file } file Excel file
     * @returns { Promise.<AdminUploadResult> }
     */
    async tournamentsTournamentIdIpUsersUploadPostAsync(tournamentId, file) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdIpUsersUploadPost(tournamentId, file, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRecordsGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRecordsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminRecordItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get tournament records
     * @param { number } tournamentId Tournament ID
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { tournamentsTournamentIdRecordsGetCallback } callback コールバック
     */
    tournamentsTournamentIdRecordsGet (tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (healthStatus === void 0) { healthStatus = null; }
        if (role === void 0) { role = null; }
        if (coronaVaccinationCount === void 0) { coronaVaccinationCount = null; }
        if (latestPcrTestResult === void 0) { latestPcrTestResult = null; }
        if (alert === void 0) { alert = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/records"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "healthStatus": healthStatus,
            "role": role,
            "coronaVaccinationCount": coronaVaccinationCount,
            "latestPcrTestResult": latestPcrTestResult,
            "alert": alert,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get tournament records
     * @param { number } tournamentId Tournament ID
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminRecordItems> }
     */
    async tournamentsTournamentIdRecordsGetAsync(tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRecordsGet(tournamentId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRemindersGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRemindersGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminReminderItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of tournament reminder.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { ?string } sort Sort order * reminderTitle * time * sendTargetId * subject * body 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { tournamentsTournamentIdRemindersGetCallback } callback コールバック
     */
    tournamentsTournamentIdRemindersGet (tournamentId, page, count, sort, order, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/reminders"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "page": page,
            "count": count,
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of tournament reminder.
     * @param { number } tournamentId Tournament ID
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { ?string } sort Sort order * reminderTitle * time * sendTargetId * subject * body 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminReminderItems> }
     */
    async tournamentsTournamentIdRemindersGetAsync(tournamentId, page, count, sort, order) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRemindersGet(tournamentId, page, count, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRemindersPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRemindersPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Create reminder.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminReminder } item Request data
     * @param { tournamentsTournamentIdRemindersPostCallback } callback コールバック
     */
    tournamentsTournamentIdRemindersPost (tournamentId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/reminders"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Create reminder.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminReminder } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdRemindersPostAsync(tournamentId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRemindersPost(tournamentId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRemindersReminderIdDeleteの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRemindersReminderIdDeleteCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @param { tournamentsTournamentIdRemindersReminderIdDeleteCallback } callback コールバック
     */
    tournamentsTournamentIdRemindersReminderIdDelete (tournamentId, reminderId, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (reminderId === void 0) { reminderId = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/reminders/{reminderId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'reminderId' + '}', encodeURIComponent(String(reminderId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "delete",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdRemindersReminderIdDeleteAsync(tournamentId, reminderId) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRemindersReminderIdDelete(tournamentId, reminderId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRemindersReminderIdGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRemindersReminderIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminReminderItem } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @param { ?string } sort Sort order * reminderTitle * time * sendTargetId * subject * body 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { tournamentsTournamentIdRemindersReminderIdGetCallback } callback コールバック
     */
    tournamentsTournamentIdRemindersReminderIdGet (tournamentId, reminderId, sort, order, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (reminderId === void 0) { reminderId = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/reminders/{reminderId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'reminderId' + '}', encodeURIComponent(String(reminderId)));

        var query = {
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @param { ?string } sort Sort order * reminderTitle * time * sendTargetId * subject * body 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminReminderItem> }
     */
    async tournamentsTournamentIdRemindersReminderIdGetAsync(tournamentId, reminderId, sort, order) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRemindersReminderIdGet(tournamentId, reminderId, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdRemindersReminderIdPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdRemindersReminderIdPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @param { SetAdminReminder } item Request data
     * @param { tournamentsTournamentIdRemindersReminderIdPostCallback } callback コールバック
     */
    tournamentsTournamentIdRemindersReminderIdPost (tournamentId, reminderId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (reminderId === void 0) { reminderId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/reminders/{reminderId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'reminderId' + '}', encodeURIComponent(String(reminderId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update reminder.
     * @param { number } tournamentId Tournament ID
     * @param { number } reminderId Reminder ID
     * @param { SetAdminReminder } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdRemindersReminderIdPostAsync(tournamentId, reminderId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdRemindersReminderIdPost(tournamentId, reminderId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdScanLogsGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdScanLogsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminScanLogItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of scanlog.
     * @param { number } tournamentId Tournament ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time (HH:mm)
     * @param { ?string } endTime End time (HH:mm)
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * dateTime * firstName * lastName * userId 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @param { tournamentsTournamentIdScanLogsGetCallback } callback コールバック
     */
    tournamentsTournamentIdScanLogsGet (tournamentId, startAt, endAt, startTime, endTime, keyword, sort, order, page, count, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (startTime === void 0) { startTime = null; }
        if (endTime === void 0) { endTime = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (page === void 0) { page = null; }
        if (count === void 0) { count = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/scanLogs"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
            "startAt": startAt,
            "endAt": endAt,
            "startTime": startTime,
            "endTime": endTime,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "page": page,
            "count": count
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of scanlog.
     * @param { number } tournamentId Tournament ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time (HH:mm)
     * @param { ?string } endTime End time (HH:mm)
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * dateTime * firstName * lastName * userId 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?number } page Index of pages to display
     * @param { ?number } count Number of items to display on one page
     * @returns { Promise.<AdminScanLogItems> }
     */
    async tournamentsTournamentIdScanLogsGetAsync(tournamentId, startAt, endAt, startTime, endTime, keyword, sort, order, page, count) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdScanLogsGet(tournamentId, startAt, endAt, startTime, endTime, keyword, sort, order, page, count, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdScanLogsScanLogIdGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdScanLogsScanLogIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminScanLogDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a list of scanlog.
     * @param { number } tournamentId Tournament ID
     * @param { number } scanLogId Scan log ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time
     * @param { ?string } endTime End time
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * dateTime * firstName * lastName * userId 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { tournamentsTournamentIdScanLogsScanLogIdGetCallback } callback コールバック
     */
    tournamentsTournamentIdScanLogsScanLogIdGet (tournamentId, scanLogId, startAt, endAt, startTime, endTime, keyword, sort, order, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (scanLogId === void 0) { scanLogId = null; }
        if (startAt === void 0) { startAt = null; }
        if (endAt === void 0) { endAt = null; }
        if (startTime === void 0) { startTime = null; }
        if (endTime === void 0) { endTime = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/scanLogs/{scanLogId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'scanLogId' + '}', encodeURIComponent(String(scanLogId)));

        var query = {
            "startAt": startAt,
            "endAt": endAt,
            "startTime": startTime,
            "endTime": endTime,
            "keyword": keyword,
            "sort": sort,
            "order": order
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a list of scanlog.
     * @param { number } tournamentId Tournament ID
     * @param { number } scanLogId Scan log ID
     * @param { ?date } startAt Start date
     * @param { ?date } endAt End date
     * @param { ?string } startTime Start time
     * @param { ?string } endTime End time
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * dateTime * firstName * lastName * userId 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @returns { Promise.<AdminScanLogDetail> }
     */
    async tournamentsTournamentIdScanLogsScanLogIdGetAsync(tournamentId, scanLogId, startAt, endAt, startTime, endTime, keyword, sort, order) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdScanLogsScanLogIdGet(tournamentId, scanLogId, startAt, endAt, startTime, endTime, keyword, sort, order, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdScanPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdScanPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminScanResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Scan.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminScan } item Request data
     * @param { tournamentsTournamentIdScanPostCallback } callback コールバック
     */
    tournamentsTournamentIdScanPost (tournamentId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/scan"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Scan.
     * @param { number } tournamentId Tournament ID
     * @param { SetAdminScan } item Request data
     * @returns { Promise.<AdminScanResult> }
     */
    async tournamentsTournamentIdScanPostAsync(tournamentId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdScanPost(tournamentId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdUserRecordsUserIdApprovalPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdUserRecordsUserIdApprovalPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Set approval
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { SetAdminApproval } item Request data
     * @param { tournamentsTournamentIdUserRecordsUserIdApprovalPostCallback } callback コールバック
     */
    tournamentsTournamentIdUserRecordsUserIdApprovalPost (tournamentId, userId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/userRecords/{userId}/approval"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Set approval
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { SetAdminApproval } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdUserRecordsUserIdApprovalPostAsync(tournamentId, userId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdUserRecordsUserIdApprovalPost(tournamentId, userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdUserRecordsUserIdDetailGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdUserRecordsUserIdDetailGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUserRecordDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get the detail tournament record of the user
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { date } date Date
     * @param { ?string } sortItem Sort order for health record items * healthStatus * date * bodyTemp * alerts * remarks 
     * @param { ?string } orderItem Ascending or Descending for health record items * asc * desc 
     * @param { tournamentsTournamentIdUserRecordsUserIdDetailGetCallback } callback コールバック
     */
    tournamentsTournamentIdUserRecordsUserIdDetailGet (tournamentId, userId, date, sortItem, orderItem, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (userId === void 0) { userId = null; }
        if (date === void 0) { date = null; }
        if (sortItem === void 0) { sortItem = null; }
        if (orderItem === void 0) { orderItem = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/userRecords/{userId}/detail"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "date": date,
            "sortItem": sortItem,
            "orderItem": orderItem
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get the detail tournament record of the user
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { date } date Date
     * @param { ?string } sortItem Sort order for health record items * healthStatus * date * bodyTemp * alerts * remarks 
     * @param { ?string } orderItem Ascending or Descending for health record items * asc * desc 
     * @returns { Promise.<AdminUserRecordDetail> }
     */
    async tournamentsTournamentIdUserRecordsUserIdDetailGetAsync(tournamentId, userId, date, sortItem, orderItem) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdUserRecordsUserIdDetailGet(tournamentId, userId, date, sortItem, orderItem, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdUserRecordsUserIdGetの結果コールバック関数
     *
     * @callback tournamentsTournamentIdUserRecordsUserIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUserRecordResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get the tournament record of the user
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?string } sortItem Sort order for health record items * healthStatus * date * bodyTemp * alerts * remarks * heatStrokeScore 
     * @param { ?string } orderItem Ascending or Descending for health record items * asc * desc 
     * @param { ?number } single Get only one of the data. * 0: The data before and after is also acquired. * 1: Gets only the specified data. 
     * @param { tournamentsTournamentIdUserRecordsUserIdGetCallback } callback コールバック
     */
    tournamentsTournamentIdUserRecordsUserIdGet (tournamentId, userId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, sortItem, orderItem, single, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (userId === void 0) { userId = null; }
        if (healthStatus === void 0) { healthStatus = null; }
        if (role === void 0) { role = null; }
        if (coronaVaccinationCount === void 0) { coronaVaccinationCount = null; }
        if (latestPcrTestResult === void 0) { latestPcrTestResult = null; }
        if (alert === void 0) { alert = null; }
        if (accountStatus === void 0) { accountStatus = null; }
        if (keyword === void 0) { keyword = null; }
        if (sort === void 0) { sort = null; }
        if (order === void 0) { order = null; }
        if (sortItem === void 0) { sortItem = null; }
        if (orderItem === void 0) { orderItem = null; }
        if (single === void 0) { single = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/userRecords/{userId}"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "healthStatus": healthStatus,
            "role": role,
            "coronaVaccinationCount": coronaVaccinationCount,
            "latestPcrTestResult": latestPcrTestResult,
            "alert": alert,
            "accountStatus": accountStatus,
            "keyword": keyword,
            "sort": sort,
            "order": order,
            "sortItem": sortItem,
            "orderItem": orderItem,
            "single": single
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get the tournament record of the user
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { ?number } healthStatus Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
     * @param { ?number } role Role ID
     * @param { ?number } coronaVaccinationCount Vaccine type * 0: All(Default) / すべて * 1: Count 0 / 0回目 * 2: Count 1 / 1回目 * 3: Count 2 / 2回目 * 4: Count 3 / 3回目 * 5: Count 4 / 4回目 * 6: Count 5 / 5回目 * 7: Count 6 / 6回目 * 8: Count 7 / 7回目 * 9: Count 8 / 8回目 * 10: Count 9 / 9回目 * 11: Count 10 / 10回目 
     * @param { ?number } latestPcrTestResult PCR Test type * 0: All(Default) / すべて * 1: Positive / 陽性 * 2: Negative / 陰性 * 3: No check / 未検査 
     * @param { ?number } alert Alert * 0: All(Default) / すべて * 1: Available / 有り * 2: Not available / 無し 
     * @param { ?number } accountStatus Account Status * 0: All(Default) / すべて * 1: Enabled / 有効 * 2: Disabled / 停止 
     * @param { ?string } keyword Search word
     * @param { ?string } sort Sort order * healthStatus * userId * firstName * lastName * userRole * organization * alerts * accountStatus * coronaVaccinationCount * latestPcrTestResult 
     * @param { ?string } order Ascending or Descending * asc * desc 
     * @param { ?string } sortItem Sort order for health record items * healthStatus * date * bodyTemp * alerts * remarks * heatStrokeScore 
     * @param { ?string } orderItem Ascending or Descending for health record items * asc * desc 
     * @param { ?number } single Get only one of the data. * 0: The data before and after is also acquired. * 1: Gets only the specified data. 
     * @returns { Promise.<AdminUserRecordResult> }
     */
    async tournamentsTournamentIdUserRecordsUserIdGetAsync(tournamentId, userId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, sortItem, orderItem, single) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdUserRecordsUserIdGet(tournamentId, userId, healthStatus, role, coronaVaccinationCount, latestPcrTestResult, alert, accountStatus, keyword, sort, order, sortItem, orderItem, single, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * tournamentsTournamentIdUserRecordsUserIdRejectionPostの結果コールバック関数
     *
     * @callback tournamentsTournamentIdUserRecordsUserIdRejectionPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Entry Rejection
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { SetAdminRejection } item Request data
     * @param { tournamentsTournamentIdUserRecordsUserIdRejectionPostCallback } callback コールバック
     */
    tournamentsTournamentIdUserRecordsUserIdRejectionPost (tournamentId, userId, item, callback) {
        if (tournamentId === void 0) { tournamentId = null; }
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/tournaments/{tournamentId}/userRecords/{userId}/rejection"
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)))
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Entry Rejection
     * @param { number } tournamentId Tournament ID
     * @param { string } userId User identification key
     * @param { SetAdminRejection } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async tournamentsTournamentIdUserRecordsUserIdRejectionPostAsync(tournamentId, userId, item) {
        return new Promise((resolve, reject) => {
            this.tournamentsTournamentIdUserRecordsUserIdRejectionPost(tournamentId, userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class UserApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * usersUserIdChangePasswordPostの結果コールバック関数
     *
     * @callback usersUserIdChangePasswordPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update a password
     * @param { string } userId User identification key
     * @param { SetAdminChangePassword } item Request data
     * @param { usersUserIdChangePasswordPostCallback } callback コールバック
     */
    usersUserIdChangePasswordPost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/changePassword"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update a password
     * @param { string } userId User identification key
     * @param { SetAdminChangePassword } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async usersUserIdChangePasswordPostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdChangePasswordPost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdDeletePostの結果コールバック関数
     *
     * @callback usersUserIdDeletePostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete a user account
     * @param { string } userId User identification key
     * @param { SetAdminDeleteUser } item Request data
     * @param { usersUserIdDeletePostCallback } callback コールバック
     */
    usersUserIdDeletePost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/delete"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete a user account
     * @param { string } userId User identification key
     * @param { SetAdminDeleteUser } item Request data
     * @returns { Promise.<AdminEmptyResult> }
     */
    async usersUserIdDeletePostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdDeletePost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdGetの結果コールバック関数
     *
     * @callback usersUserIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminUser } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a user account
     * @param { string } userId User identification key
     * @param { usersUserIdGetCallback } callback コールバック
     */
    usersUserIdGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a user account
     * @param { string } userId User identification key
     * @returns { Promise.<AdminUser> }
     */
    async usersUserIdGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdLogoutPostの結果コールバック関数
     *
     * @callback usersUserIdLogoutPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Logout
     * @param { string } userId User identification key
     * @param { usersUserIdLogoutPostCallback } callback コールバック
     */
    usersUserIdLogoutPost (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/logout"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Logout
     * @param { string } userId User identification key
     * @returns { Promise.<AdminEmptyResult> }
     */
    async usersUserIdLogoutPostAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdLogoutPost(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdUpdatePostの結果コールバック関数
     *
     * @callback usersUserIdUpdatePostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?AdminEmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update a user account
     * @param { string } userId User identification key
     * @param { SetAdminUpdateUser } item Request data
     * @param { ?number } dryRun Only verification is performed without registration. If it is 1, only verification is performed. 
     * @param { usersUserIdUpdatePostCallback } callback コールバック
     */
    usersUserIdUpdatePost (userId, item, dryRun, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }
        if (dryRun === void 0) { dryRun = 0; }

        var url = this._config.basepath + "/users/{userId}/update"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "dryRun": dryRun
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update a user account
     * @param { string } userId User identification key
     * @param { SetAdminUpdateUser } item Request data
     * @param { ?number } dryRun Only verification is performed without registration. If it is 1, only verification is performed. 
     * @returns { Promise.<AdminEmptyResult> }
     */
    async usersUserIdUpdatePostAsync(userId, item, dryRun) {
        return new Promise((resolve, reject) => {
            this.usersUserIdUpdatePost(userId, item, dryRun, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}


/**
 * `Compatible with Involved People Rest API` authenticated object 
 * @constructor
 */
export class AdminAuthenticated {
    constructor() {
        /**
         * @type { AdminUser } 
         */
        this.user = null;
        /**
         * @type { string } API token key
         */
        this.apiToken = null;
        /**
         * @type { Date } Token time to live
         */
        this.tokenExpiredAt = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminAuthenticated }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminAuthenticated();
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.apiToken) !== "undefined") {
            obj.apiToken = jsonObject.apiToken;
        }
        if (typeof(jsonObject.tokenExpiredAt) !== "undefined") {
            obj.tokenExpiredAt = jsonObject.tokenExpiredAt;
        }
        return obj;
  }
}

/*
var request_data = new AdminAuthenticated()
request_data.user = null;
request_data.apiToken = null;
request_data.tokenExpiredAt = null;
*/

/**
 * `Compatible with Involved People Rest API` credentials object 
 * @constructor
 */
export class AdminCredentials {
    constructor() {
        /**
         * @type { string } E-mail
         */
        this.email = null;
        /**
         * @type { string } Password
         */
        this.password = null;
        /**
         * @type { ?date } Birthday
         */
        this.birthday = null;
        /**
         * @type { ?boolean } Remember flag * true: Preserve the login details * false: Do not preserve the login details 
         */
        this.remember = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminCredentials }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminCredentials();
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.password) !== "undefined") {
            obj.password = jsonObject.password;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.remember) !== "undefined") {
            obj.remember = jsonObject.remember;
        }
        return obj;
  }
}

/*
var request_data = new AdminCredentials()
request_data.email = null;
request_data.password = null;
request_data.birthday = null;
request_data.remember = null;
*/

/**
 * Returns an empty object.
 * @constructor
 */
export class AdminEmptyResult {
    constructor() {
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminEmptyResult }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminEmptyResult();
        return obj;
  }
}

/*
var request_data = new AdminEmptyResult()
*/

/**
 * `Compatible with Involved People Rest API` Error object If result is `ERR_VALIDATION`, contains the key and message where the error occurred. If the request data is nested, use a period. If there is an error in the array, the zero-based indexes are combined to create the key name.  Example: ``` {   \"result\": \"ERR_VALIDATION\",   \"message\": \"The given data was invalid.\",   \"validationErrors\" = [     {       \"name\": \"foo\",       \"message\": \"foo error message\",     },     {       \"name\": \"bar.baz\",       \"message\": \"baz error message\",     },     {       \"name\": \"corge.0.grault\",       \"message\": \"grault error message\"     }   ] } ``` 
 * @constructor
 */
export class AdminErrorResult {
    constructor() {
        /**
         * @type { string } Result description * ERR_UNKNOWN: An error has occurred on the server side.<br> * ERR_NOTFOUND: The specified page cannot be found.<br> * ERR_FORBIDDEN: You do not have API permissions. The client key or token specification is invalid.<br> * ERR_EXPIRE_TOKEN: Token has expired.<br> * ERR_INVALID_TOKEN: Invalid Token<br> * ERR_LOGIN_REQUIRE_BIRTHDAY: Need to enter the password when logging in.<br> * ERR_VALIDATION: The requested value has an invalid value. Keys and messages are stored in pairs in `validationErrors`.<br> * ERR_TOO_MANY_REQUESTS: Access is denied because there are too many requests.        <br> 
         */
        this.result = null;
        /**
         * @type { string } Error message
         */
        this.message = null;
        /**
         * @type { ?Array.<AdminValidationError> } 
         */
        this.validationErrors = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminErrorResult }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminErrorResult();
        if (typeof(jsonObject.result) !== "undefined") {
            obj.result = jsonObject.result;
        }
        if (typeof(jsonObject.message) !== "undefined") {
            obj.message = jsonObject.message;
        }
        if (typeof(jsonObject.validationErrors) !== "undefined") {
            if (jsonObject.validationErrors !== null) {
                obj.validationErrors = [];
                for (let i = 0; i < jsonObject.validationErrors.length; i++) {
                    const item = AdminValidationError.createFromJson(jsonObject.validationErrors[i]);
                    obj.validationErrors.push(item);
                }
            } else {
                obj.validationErrors = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminErrorResult()
request_data.result = null;
request_data.message = null;
request_data.validationErrors = null;
*/

/**
 * IP User object
 * @constructor
 */
export class AdminIPUserItem {
    constructor() {
        /**
         * @type { ?AdminUser } 
         */
        this.user = null;
        /**
         * @type { number } Account status * 1: Enabled / 有効 * 2: Disabled / 停止 
         */
        this.accountStatus = null;
        /**
         * @type { boolean } Require answers in the last 14 days
         */
        this.pastAnswersRequired = null;
        /**
         * @type { number } Role ID
         */
        this.userRoleId = null;
        /**
         * @type { string } Role Name
         */
        this.userRoleName = null;
        /**
         * @type { ?string } Organization
         */
        this.organization = null;
        /**
         * @type { ?string } Previous user ID
         */
        this.prevUserId = null;
        /**
         * @type { ?string } Next user ID
         */
        this.nextUserId = null;
        /**
         * @type { ?string } Previous user name
         */
        this.prevUserName = null;
        /**
         * @type { ?string } Next user name
         */
        this.nextUserName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminIPUserItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminIPUserItem();
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.accountStatus) !== "undefined") {
            obj.accountStatus = jsonObject.accountStatus;
        }
        if (typeof(jsonObject.pastAnswersRequired) !== "undefined") {
            obj.pastAnswersRequired = jsonObject.pastAnswersRequired;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.userRoleName) !== "undefined") {
            obj.userRoleName = jsonObject.userRoleName;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.prevUserId) !== "undefined") {
            obj.prevUserId = jsonObject.prevUserId;
        }
        if (typeof(jsonObject.nextUserId) !== "undefined") {
            obj.nextUserId = jsonObject.nextUserId;
        }
        if (typeof(jsonObject.prevUserName) !== "undefined") {
            obj.prevUserName = jsonObject.prevUserName;
        }
        if (typeof(jsonObject.nextUserName) !== "undefined") {
            obj.nextUserName = jsonObject.nextUserName;
        }
        return obj;
  }
}

/*
var request_data = new AdminIPUserItem()
request_data.user = null;
request_data.accountStatus = null;
request_data.pastAnswersRequired = null;
request_data.userRoleId = null;
request_data.userRoleName = null;
request_data.organization = null;
request_data.prevUserId = null;
request_data.nextUserId = null;
request_data.prevUserName = null;
request_data.nextUserName = null;
*/

/**
 * IP User list object
 * @constructor
 */
export class AdminIPUserItems {
    constructor() {
        /**
         * @type { Array.<AdminIPUserItem> } List of user. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminIPUserItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminIPUserItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminIPUserItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminIPUserItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Log detail object
 * @constructor
 */
export class AdminJTALogDetail {
    constructor() {
        /**
         * @type { number } Log ID
         */
        this.logId = null;
        /**
         * @type { Date } Log ID
         */
        this.dateTime = null;
        /**
         * @type { string } Operation
         */
        this.operation = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
        /**
         * @type { string } Target
         */
        this.target = null;
        /**
         * @type { ?string } Contents JSON String
         */
        this.contents = null;
        /**
         * @type { ?string } Previous log ID
         */
        this.prevLogId = null;
        /**
         * @type { ?string } Previous scan ID
         */
        this.prevUserId = null;
        /**
         * @type { ?string } Previous user name
         */
        this.prevUserName = null;
        /**
         * @type { ?string } Next log ID
         */
        this.nextLogId = null;
        /**
         * @type { ?string } Next scan log name
         */
        this.nextUserId = null;
        /**
         * @type { ?string } Next user name
         */
        this.nextUserName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTALogDetail }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTALogDetail();
        if (typeof(jsonObject.logId) !== "undefined") {
            obj.logId = jsonObject.logId;
        }
        if (typeof(jsonObject.dateTime) !== "undefined") {
            obj.dateTime = jsonObject.dateTime;
        }
        if (typeof(jsonObject.operation) !== "undefined") {
            obj.operation = jsonObject.operation;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.target) !== "undefined") {
            obj.target = jsonObject.target;
        }
        if (typeof(jsonObject.contents) !== "undefined") {
            obj.contents = jsonObject.contents;
        }
        if (typeof(jsonObject.prevLogId) !== "undefined") {
            obj.prevLogId = jsonObject.prevLogId;
        }
        if (typeof(jsonObject.prevUserId) !== "undefined") {
            obj.prevUserId = jsonObject.prevUserId;
        }
        if (typeof(jsonObject.prevUserName) !== "undefined") {
            obj.prevUserName = jsonObject.prevUserName;
        }
        if (typeof(jsonObject.nextLogId) !== "undefined") {
            obj.nextLogId = jsonObject.nextLogId;
        }
        if (typeof(jsonObject.nextUserId) !== "undefined") {
            obj.nextUserId = jsonObject.nextUserId;
        }
        if (typeof(jsonObject.nextUserName) !== "undefined") {
            obj.nextUserName = jsonObject.nextUserName;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTALogDetail()
request_data.logId = null;
request_data.dateTime = null;
request_data.operation = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.target = null;
request_data.contents = null;
request_data.prevLogId = null;
request_data.prevUserId = null;
request_data.prevUserName = null;
request_data.nextLogId = null;
request_data.nextUserId = null;
request_data.nextUserName = null;
*/

/**
 * Log object
 * @constructor
 */
export class AdminJTALogItem {
    constructor() {
        /**
         * @type { number } Log ID
         */
        this.logId = null;
        /**
         * @type { Date } Log ID
         */
        this.dateTime = null;
        /**
         * @type { string } Operation
         */
        this.operation = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
        /**
         * @type { string } Target
         */
        this.target = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTALogItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTALogItem();
        if (typeof(jsonObject.logId) !== "undefined") {
            obj.logId = jsonObject.logId;
        }
        if (typeof(jsonObject.dateTime) !== "undefined") {
            obj.dateTime = jsonObject.dateTime;
        }
        if (typeof(jsonObject.operation) !== "undefined") {
            obj.operation = jsonObject.operation;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.target) !== "undefined") {
            obj.target = jsonObject.target;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTALogItem()
request_data.logId = null;
request_data.dateTime = null;
request_data.operation = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.target = null;
*/

/**
 * Log list object
 * @constructor
 */
export class AdminJTALogItems {
    constructor() {
        /**
         * @type { Array.<AdminJTALogItem> } List of log. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTALogItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTALogItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminJTALogItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTALogItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Tournament object These values are entered only when it is specified individually. `prevTournamentId` / `nextTournamentId` / `prevTournamentName` / `nextTournamentName` 
 * @constructor
 */
export class AdminJTATournamentItem {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { date } Input start at
         */
        this.inputStartAt = null;
        /**
         * @type { date } Input end at
         */
        this.inputEndAt = null;
        /**
         * @type { number } * Coming soon / 近日開始 = 1 * During the duration / 期間中 = 2 * Ended / 終了 = 3 
         */
        this.status = null;
        /**
         * @type { number } Host prefecture IDA
         */
        this.hostPrefectureId = null;
        /**
         * @type { string } Host prefecture
         */
        this.hostPrefecture = null;
        /**
         * @type { ?string } Host organization
         */
        this.hostOrganization = null;
        /**
         * @type { ?string } Inquiry phone no
         */
        this.inquiryPhoneNo = null;
        /**
         * @type { ?string } Inquiry email
         */
        this.inquiryEmail = null;
        /**
         * @type { ?string } Tournament admin email
         */
        this.tournamentAdminEmail = null;
        /**
         * @type { boolean } Require answers in the last 14 days
         */
        this.pastAnswersRequired = null;
        /**
         * @type { boolean } Has heat stroke.
         */
        this.hasHeatStroke = null;
        /**
         * @type { ?number } Previous tournament ID
         */
        this.prevTournamentId = null;
        /**
         * @type { ?number } Next tournament ID
         */
        this.nextTournamentId = null;
        /**
         * @type { ?string } Previous tournament Name
         */
        this.prevTournamentName = null;
        /**
         * @type { ?string } Next tournament Name
         */
        this.nextTournamentName = null;
        /**
         * @type { ?string } Register URL
         */
        this.registerURL = null;
        /**
         * @type { ?string } Billing destination
         */
        this.billingDestination = null;
        /**
         * @type { ?string } Login URL
         */
        this.loginURL = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTATournamentItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTATournamentItem();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.inputStartAt) !== "undefined") {
            obj.inputStartAt = jsonObject.inputStartAt;
        }
        if (typeof(jsonObject.inputEndAt) !== "undefined") {
            obj.inputEndAt = jsonObject.inputEndAt;
        }
        if (typeof(jsonObject.status) !== "undefined") {
            obj.status = jsonObject.status;
        }
        if (typeof(jsonObject.hostPrefectureId) !== "undefined") {
            obj.hostPrefectureId = jsonObject.hostPrefectureId;
        }
        if (typeof(jsonObject.hostPrefecture) !== "undefined") {
            obj.hostPrefecture = jsonObject.hostPrefecture;
        }
        if (typeof(jsonObject.hostOrganization) !== "undefined") {
            obj.hostOrganization = jsonObject.hostOrganization;
        }
        if (typeof(jsonObject.inquiryPhoneNo) !== "undefined") {
            obj.inquiryPhoneNo = jsonObject.inquiryPhoneNo;
        }
        if (typeof(jsonObject.inquiryEmail) !== "undefined") {
            obj.inquiryEmail = jsonObject.inquiryEmail;
        }
        if (typeof(jsonObject.tournamentAdminEmail) !== "undefined") {
            obj.tournamentAdminEmail = jsonObject.tournamentAdminEmail;
        }
        if (typeof(jsonObject.pastAnswersRequired) !== "undefined") {
            obj.pastAnswersRequired = jsonObject.pastAnswersRequired;
        }
        if (typeof(jsonObject.hasHeatStroke) !== "undefined") {
            obj.hasHeatStroke = jsonObject.hasHeatStroke;
        }
        if (typeof(jsonObject.prevTournamentId) !== "undefined") {
            obj.prevTournamentId = jsonObject.prevTournamentId;
        }
        if (typeof(jsonObject.nextTournamentId) !== "undefined") {
            obj.nextTournamentId = jsonObject.nextTournamentId;
        }
        if (typeof(jsonObject.prevTournamentName) !== "undefined") {
            obj.prevTournamentName = jsonObject.prevTournamentName;
        }
        if (typeof(jsonObject.nextTournamentName) !== "undefined") {
            obj.nextTournamentName = jsonObject.nextTournamentName;
        }
        if (typeof(jsonObject.registerURL) !== "undefined") {
            obj.registerURL = jsonObject.registerURL;
        }
        if (typeof(jsonObject.billingDestination) !== "undefined") {
            obj.billingDestination = jsonObject.billingDestination;
        }
        if (typeof(jsonObject.loginURL) !== "undefined") {
            obj.loginURL = jsonObject.loginURL;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTATournamentItem()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.inputStartAt = null;
request_data.inputEndAt = null;
request_data.status = null;
request_data.hostPrefectureId = null;
request_data.hostPrefecture = null;
request_data.hostOrganization = null;
request_data.inquiryPhoneNo = null;
request_data.inquiryEmail = null;
request_data.tournamentAdminEmail = null;
request_data.pastAnswersRequired = null;
request_data.hasHeatStroke = null;
request_data.prevTournamentId = null;
request_data.nextTournamentId = null;
request_data.prevTournamentName = null;
request_data.nextTournamentName = null;
request_data.registerURL = null;
request_data.billingDestination = null;
request_data.loginURL = null;
*/

/**
 * Tournament list object
 * @constructor
 */
export class AdminJTATournamentItems {
    constructor() {
        /**
         * @type { Array.<AdminJTATournamentItem> } List of user. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTATournamentItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTATournamentItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminJTATournamentItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTATournamentItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * User object These values are entered only when it is specified individually. `prevUserId` / `nextUserId` / `prevUserName` / `nextUserName` 
 * @constructor
 */
export class AdminJTAUserItem {
    constructor() {
        /**
         * @type { AdminUser } 
         */
        this.user = null;
        /**
         * @type { boolean } Tournament administrator
         */
        this.isTournamentAdmin = null;
        /**
         * @type { number } Account status * 1: Enabled / 有効 * 2: Disabled / 停止 
         */
        this.accountStatus = null;
        /**
         * @type { ?string } Previous user ID
         */
        this.prevUserId = null;
        /**
         * @type { ?string } Next user ID
         */
        this.nextUserId = null;
        /**
         * @type { ?string } Previous user name
         */
        this.prevUserName = null;
        /**
         * @type { ?string } Next user name
         */
        this.nextUserName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTAUserItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTAUserItem();
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.isTournamentAdmin) !== "undefined") {
            obj.isTournamentAdmin = jsonObject.isTournamentAdmin;
        }
        if (typeof(jsonObject.accountStatus) !== "undefined") {
            obj.accountStatus = jsonObject.accountStatus;
        }
        if (typeof(jsonObject.prevUserId) !== "undefined") {
            obj.prevUserId = jsonObject.prevUserId;
        }
        if (typeof(jsonObject.nextUserId) !== "undefined") {
            obj.nextUserId = jsonObject.nextUserId;
        }
        if (typeof(jsonObject.prevUserName) !== "undefined") {
            obj.prevUserName = jsonObject.prevUserName;
        }
        if (typeof(jsonObject.nextUserName) !== "undefined") {
            obj.nextUserName = jsonObject.nextUserName;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTAUserItem()
request_data.user = null;
request_data.isTournamentAdmin = null;
request_data.accountStatus = null;
request_data.prevUserId = null;
request_data.nextUserId = null;
request_data.prevUserName = null;
request_data.nextUserName = null;
*/

/**
 * User list object
 * @constructor
 */
export class AdminJTAUserItems {
    constructor() {
        /**
         * @type { Array.<AdminJTAUserItem> } List of user. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminJTAUserItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminJTAUserItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminJTAUserItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminJTAUserItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Nationality object
 * @constructor
 */
export class AdminNationalityItem {
    constructor() {
        /**
         * @type { number } Nationality Id
         */
        this.nationalityId = null;
        /**
         * @type { string } Nationality name
         */
        this.nationalityName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminNationalityItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminNationalityItem();
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityName) !== "undefined") {
            obj.nationalityName = jsonObject.nationalityName;
        }
        return obj;
  }
}

/*
var request_data = new AdminNationalityItem()
request_data.nationalityId = null;
request_data.nationalityName = null;
*/

/**
 * List of nationality object
 * @constructor
 */
export class AdminNationalityItems {
    constructor() {
        /**
         * @type { Array.<AdminNationalityItem> } List of nationalities. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminNationalityItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminNationalityItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminNationalityItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminNationalityItems()
request_data.items = null;
*/

/**
 * Paging object
 * @constructor
 */
export class AdminPaging {
    constructor() {
        /**
         * @type { number } Current page number starting by 1
         */
        this.page = null;
        /**
         * @type { number } Number to display on one page
         */
        this.pageParCount = null;
        /**
         * @type { number } Total number of pages
         */
        this.pageCount = null;
        /**
         * @type { number } Total number of searches
         */
        this.totalCount = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminPaging }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminPaging();
        if (typeof(jsonObject.page) !== "undefined") {
            obj.page = jsonObject.page;
        }
        if (typeof(jsonObject.pageParCount) !== "undefined") {
            obj.pageParCount = jsonObject.pageParCount;
        }
        if (typeof(jsonObject.pageCount) !== "undefined") {
            obj.pageCount = jsonObject.pageCount;
        }
        if (typeof(jsonObject.totalCount) !== "undefined") {
            obj.totalCount = jsonObject.totalCount;
        }
        return obj;
  }
}

/*
var request_data = new AdminPaging()
request_data.page = null;
request_data.pageParCount = null;
request_data.pageCount = null;
request_data.totalCount = null;
*/

/**
 * Prefecture object
 * @constructor
 */
export class AdminPrefectureItem {
    constructor() {
        /**
         * @type { number } Prefecture Id
         */
        this.prefectureId = null;
        /**
         * @type { string } Prefecture name
         */
        this.prefectureName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminPrefectureItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminPrefectureItem();
        if (typeof(jsonObject.prefectureId) !== "undefined") {
            obj.prefectureId = jsonObject.prefectureId;
        }
        if (typeof(jsonObject.prefectureName) !== "undefined") {
            obj.prefectureName = jsonObject.prefectureName;
        }
        return obj;
  }
}

/*
var request_data = new AdminPrefectureItem()
request_data.prefectureId = null;
request_data.prefectureName = null;
*/

/**
 * List of prefecture object
 * @constructor
 */
export class AdminPrefectureItems {
    constructor() {
        /**
         * @type { Array.<AdminPrefectureItem> } List of prefecture. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminPrefectureItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminPrefectureItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminPrefectureItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminPrefectureItems()
request_data.items = null;
*/

/**
 * Record answer object
 * @constructor
 */
export class AdminRecordAnswer {
    constructor() {
        /**
         * @type { string } Title
         */
        this.title = null;
        /**
         * @type { string } Value
         */
        this.value = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminRecordAnswer }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminRecordAnswer();
        if (typeof(jsonObject.title) !== "undefined") {
            obj.title = jsonObject.title;
        }
        if (typeof(jsonObject.value) !== "undefined") {
            obj.value = jsonObject.value;
        }
        return obj;
  }
}

/*
var request_data = new AdminRecordAnswer()
request_data.title = null;
request_data.value = null;
*/

/**
 * Tournament summary object
 * @constructor
 */
export class AdminRecordItem {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
        /**
         * @type { ?string } User role ID
         */
        this.userRoleId = null;
        /**
         * @type { string } User role
         */
        this.userRole = null;
        /**
         * @type { string } Organization
         */
        this.organization = null;
        /**
         * @type { number } Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
         */
        this.healthStatus = null;
        /**
         * @type { Array.<string> } List of alerts 
         */
        this.alerts = null;
        /**
         * @type { number } Account status * 1: Enabled / 有効 * 2: Disabled / 停止 
         */
        this.accountStatus = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminRecordItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminRecordItem();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.userRole) !== "undefined") {
            obj.userRole = jsonObject.userRole;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.healthStatus) !== "undefined") {
            obj.healthStatus = jsonObject.healthStatus;
        }
        if (typeof(jsonObject.alerts) !== "undefined") {
            if (jsonObject.alerts !== null) {
                obj.alerts = [];
                for (let i = 0; i < jsonObject.alerts.length; i++) {
                    const item = jsonObject.alerts[i];
                    obj.alerts.push(item);
                }
            } else {
                obj.alerts = null;
            }
        }
        if (typeof(jsonObject.accountStatus) !== "undefined") {
            obj.accountStatus = jsonObject.accountStatus;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        return obj;
  }
}

/*
var request_data = new AdminRecordItem()
request_data.tournamentId = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.userRoleId = null;
request_data.userRole = null;
request_data.organization = null;
request_data.healthStatus = null;
request_data.alerts = null;
request_data.accountStatus = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
*/

/**
 * Record list object
 * @constructor
 */
export class AdminRecordItems {
    constructor() {
        /**
         * @type { Array.<AdminRecordItem> } List of record. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminRecordItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminRecordItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminRecordItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminRecordItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Reminder object
 * @constructor
 */
export class AdminReminderItem {
    constructor() {
        /**
         * @type { number } Reminder ID
         */
        this.reminderId = null;
        /**
         * @type { string } Reminder Title
         */
        this.reminderTitle = null;
        /**
         * @type { number } Send hour (0-23)
         */
        this.hour = null;
        /**
         * @type { number } Send minute (0-59)
         */
        this.minute = null;
        /**
         * @type { number } Send target ID * 1: All involved people / 関係者全員 
         */
        this.sendTargetId = null;
        /**
         * @type { string } Reminder subject
         */
        this.subject = null;
        /**
         * @type { string } Reminder body
         */
        this.body = null;
        /**
         * @type { ?string } Previous reminder ID
         */
        this.prevReminderId = null;
        /**
         * @type { ?string } Next reminder ID
         */
        this.nextReminderId = null;
        /**
         * @type { ?string } Previous reminder name
         */
        this.prevReminderName = null;
        /**
         * @type { ?string } Next reminder name
         */
        this.nextReminderName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminReminderItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminReminderItem();
        if (typeof(jsonObject.reminderId) !== "undefined") {
            obj.reminderId = jsonObject.reminderId;
        }
        if (typeof(jsonObject.reminderTitle) !== "undefined") {
            obj.reminderTitle = jsonObject.reminderTitle;
        }
        if (typeof(jsonObject.hour) !== "undefined") {
            obj.hour = jsonObject.hour;
        }
        if (typeof(jsonObject.minute) !== "undefined") {
            obj.minute = jsonObject.minute;
        }
        if (typeof(jsonObject.sendTargetId) !== "undefined") {
            obj.sendTargetId = jsonObject.sendTargetId;
        }
        if (typeof(jsonObject.subject) !== "undefined") {
            obj.subject = jsonObject.subject;
        }
        if (typeof(jsonObject.body) !== "undefined") {
            obj.body = jsonObject.body;
        }
        if (typeof(jsonObject.prevReminderId) !== "undefined") {
            obj.prevReminderId = jsonObject.prevReminderId;
        }
        if (typeof(jsonObject.nextReminderId) !== "undefined") {
            obj.nextReminderId = jsonObject.nextReminderId;
        }
        if (typeof(jsonObject.prevReminderName) !== "undefined") {
            obj.prevReminderName = jsonObject.prevReminderName;
        }
        if (typeof(jsonObject.nextReminderName) !== "undefined") {
            obj.nextReminderName = jsonObject.nextReminderName;
        }
        return obj;
  }
}

/*
var request_data = new AdminReminderItem()
request_data.reminderId = null;
request_data.reminderTitle = null;
request_data.hour = null;
request_data.minute = null;
request_data.sendTargetId = null;
request_data.subject = null;
request_data.body = null;
request_data.prevReminderId = null;
request_data.nextReminderId = null;
request_data.prevReminderName = null;
request_data.nextReminderName = null;
*/

/**
 * Reminder list object
 * @constructor
 */
export class AdminReminderItems {
    constructor() {
        /**
         * @type { Array.<AdminReminderItem> } List of reminder. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminReminderItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminReminderItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminReminderItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminReminderItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Role object
 * @constructor
 */
export class AdminRoleItem {
    constructor() {
        /**
         * @type { number } Role Id
         */
        this.roleId = null;
        /**
         * @type { string } Role name
         */
        this.roleName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminRoleItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminRoleItem();
        if (typeof(jsonObject.roleId) !== "undefined") {
            obj.roleId = jsonObject.roleId;
        }
        if (typeof(jsonObject.roleName) !== "undefined") {
            obj.roleName = jsonObject.roleName;
        }
        return obj;
  }
}

/*
var request_data = new AdminRoleItem()
request_data.roleId = null;
request_data.roleName = null;
*/

/**
 * List of role object
 * @constructor
 */
export class AdminRoleItems {
    constructor() {
        /**
         * @type { Array.<AdminRoleItem> } List of role. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminRoleItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminRoleItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminRoleItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminRoleItems()
request_data.items = null;
*/

/**
 * Scan log detail object
 * @constructor
 */
export class AdminScanLogDetail {
    constructor() {
        /**
         * @type { number } Scan log ID
         */
        this.scanLogId = null;
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { Date } Enter date time
         */
        this.dateTime = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
        /**
         * @type { ?number } Previous scan log ID
         */
        this.prevScanLogId = null;
        /**
         * @type { ?string } Previous scan ID
         */
        this.prevUserId = null;
        /**
         * @type { ?string } Previous user name
         */
        this.prevUserName = null;
        /**
         * @type { ?number } Next scan log ID
         */
        this.nextScanLogId = null;
        /**
         * @type { ?string } Next scan log name
         */
        this.nextUserId = null;
        /**
         * @type { ?string } Next user name
         */
        this.nextUserName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminScanLogDetail }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminScanLogDetail();
        if (typeof(jsonObject.scanLogId) !== "undefined") {
            obj.scanLogId = jsonObject.scanLogId;
        }
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.dateTime) !== "undefined") {
            obj.dateTime = jsonObject.dateTime;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.prevScanLogId) !== "undefined") {
            obj.prevScanLogId = jsonObject.prevScanLogId;
        }
        if (typeof(jsonObject.prevUserId) !== "undefined") {
            obj.prevUserId = jsonObject.prevUserId;
        }
        if (typeof(jsonObject.prevUserName) !== "undefined") {
            obj.prevUserName = jsonObject.prevUserName;
        }
        if (typeof(jsonObject.nextScanLogId) !== "undefined") {
            obj.nextScanLogId = jsonObject.nextScanLogId;
        }
        if (typeof(jsonObject.nextUserId) !== "undefined") {
            obj.nextUserId = jsonObject.nextUserId;
        }
        if (typeof(jsonObject.nextUserName) !== "undefined") {
            obj.nextUserName = jsonObject.nextUserName;
        }
        return obj;
  }
}

/*
var request_data = new AdminScanLogDetail()
request_data.scanLogId = null;
request_data.tournamentId = null;
request_data.dateTime = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.prevScanLogId = null;
request_data.prevUserId = null;
request_data.prevUserName = null;
request_data.nextScanLogId = null;
request_data.nextUserId = null;
request_data.nextUserName = null;
*/

/**
 * Scan log object
 * @constructor
 */
export class AdminScanLogItem {
    constructor() {
        /**
         * @type { number } Scan log ID
         */
        this.scanLogId = null;
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { Date } Enter date time
         */
        this.dateTime = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminScanLogItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminScanLogItem();
        if (typeof(jsonObject.scanLogId) !== "undefined") {
            obj.scanLogId = jsonObject.scanLogId;
        }
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.dateTime) !== "undefined") {
            obj.dateTime = jsonObject.dateTime;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        return obj;
  }
}

/*
var request_data = new AdminScanLogItem()
request_data.scanLogId = null;
request_data.tournamentId = null;
request_data.dateTime = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
*/

/**
 * Scan log list object
 * @constructor
 */
export class AdminScanLogItems {
    constructor() {
        /**
         * @type { Array.<AdminScanLogItem> } List of scan log. 
         */
        this.items = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sort = null;
        /**
         * @type { ?string } Input sort order
         */
        this.order = null;
        /**
         * @type { AdminPaging } 
         */
        this.paging = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminScanLogItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminScanLogItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminScanLogItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.sort) !== "undefined") {
            obj.sort = jsonObject.sort;
        }
        if (typeof(jsonObject.order) !== "undefined") {
            obj.order = jsonObject.order;
        }
        if (typeof(jsonObject.paging) !== "undefined") {
            obj.paging = jsonObject.paging;
        }
        return obj;
  }
}

/*
var request_data = new AdminScanLogItems()
request_data.items = null;
request_data.sort = null;
request_data.order = null;
request_data.paging = null;
*/

/**
 * Scan log result.
 * @constructor
 */
export class AdminScanResult {
    constructor() {
        /**
         * @type { number } Scan log ID
         */
        this.scanLogId = null;
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { AdminUser } 
         */
        this.user = null;
        /**
         * @type { number } Enter status * 1: Good / ○ * 2: Bad / × 
         */
        this.enterStatus = null;
        /**
         * @type { number } User role ID
         */
        this.userRoleId = null;
        /**
         * @type { string } User role
         */
        this.userRole = null;
        /**
         * @type { string } Organization
         */
        this.organization = null;
        /**
         * @type { Array.<number> } List of reasons for bad. 
         */
        this.badReasons = null;
        /**
         * @type { ?string } Return the score of today's heat stroke. If there is no input for heat stroke, null will be returned. 
         */
        this.heatStrokeScore = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminScanResult }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminScanResult();
        if (typeof(jsonObject.scanLogId) !== "undefined") {
            obj.scanLogId = jsonObject.scanLogId;
        }
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.enterStatus) !== "undefined") {
            obj.enterStatus = jsonObject.enterStatus;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.userRole) !== "undefined") {
            obj.userRole = jsonObject.userRole;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.badReasons) !== "undefined") {
            if (jsonObject.badReasons !== null) {
                obj.badReasons = [];
                for (let i = 0; i < jsonObject.badReasons.length; i++) {
                    const item = integer.createFromJson(jsonObject.badReasons[i]);
                    obj.badReasons.push(item);
                }
            } else {
                obj.badReasons = null;
            }
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        return obj;
  }
}

/*
var request_data = new AdminScanResult()
request_data.scanLogId = null;
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.user = null;
request_data.enterStatus = null;
request_data.userRoleId = null;
request_data.userRole = null;
request_data.organization = null;
request_data.badReasons = null;
request_data.heatStrokeScore = null;
*/

/**
 * Tournament object
 * @constructor
 */
export class AdminTournamentItem {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { boolean } For tournaments with this flag, Require answers in the last 14 days will be forcibly enabled at the time of IP User registration. 
         */
        this.forcePastAnswersRequired = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminTournamentItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminTournamentItem();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.forcePastAnswersRequired) !== "undefined") {
            obj.forcePastAnswersRequired = jsonObject.forcePastAnswersRequired;
        }
        return obj;
  }
}

/*
var request_data = new AdminTournamentItem()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.forcePastAnswersRequired = null;
*/

/**
 * Tournament list object
 * @constructor
 */
export class AdminTournamentItems {
    constructor() {
        /**
         * @type { Array.<AdminTournamentItem> } List of tournament. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminTournamentItems }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminTournamentItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminTournamentItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminTournamentItems()
request_data.items = null;
*/

/**
 * Tournament summary list object
 * @constructor
 */
export class AdminTournamentSummaries {
    constructor() {
        /**
         * @type { Array.<AdminTournamentSummary> } List of tournament. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminTournamentSummaries }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminTournamentSummaries();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminTournamentSummary.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminTournamentSummaries()
request_data.items = null;
*/

/**
 * Tournament summary object
 * @constructor
 */
export class AdminTournamentSummary {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { ?string } Register URL
         */
        this.registerURL = null;
        /**
         * @type { ?string } Login URL
         */
        this.loginURL = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { date } Input start at
         */
        this.inputStartAt = null;
        /**
         * @type { date } Input end at
         */
        this.inputEndAt = null;
        /**
         * @type { number } * Coming soon / 近日開始 = 1 * During the duration / 期間中 = 2 * Ended / 終了 = 3 
         */
        this.tournamentStatus = null;
        /**
         * @type { number } Number of rejection today
         */
        this.todayHealthStatusCountRejection = null;
        /**
         * @type { number } Number of abnormality today
         */
        this.todayHealthStatusCountAbnormality = null;
        /**
         * @type { number } Number of no input today
         */
        this.todayHealthStatusCountNoInput = null;
        /**
         * @type { number } Number of acceptation today
         */
        this.todayHealthStatusCountAcceptation = null;
        /**
         * @type { number } Number of normality today
         */
        this.todayHealthStatusCountNormality = null;
        /**
         * @type { number } Number of rejection last 14 days
         */
        this.last14daysHealthStatusCountRejection = null;
        /**
         * @type { number } Number of abnormality last 14 days
         */
        this.last14daysHealthStatusCountAbnormality = null;
        /**
         * @type { number } Number of no input last 14 days
         */
        this.last14daysHealthStatusCountNoInput = null;
        /**
         * @type { number } Number of acceptation last 14 days
         */
        this.last14daysHealthStatusCountAcceptation = null;
        /**
         * @type { number } Number of normality last 14 days
         */
        this.last14daysHealthStatusCountNormality = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminTournamentSummary }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminTournamentSummary();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.registerURL) !== "undefined") {
            obj.registerURL = jsonObject.registerURL;
        }
        if (typeof(jsonObject.loginURL) !== "undefined") {
            obj.loginURL = jsonObject.loginURL;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.inputStartAt) !== "undefined") {
            obj.inputStartAt = jsonObject.inputStartAt;
        }
        if (typeof(jsonObject.inputEndAt) !== "undefined") {
            obj.inputEndAt = jsonObject.inputEndAt;
        }
        if (typeof(jsonObject.tournamentStatus) !== "undefined") {
            obj.tournamentStatus = jsonObject.tournamentStatus;
        }
        if (typeof(jsonObject.todayHealthStatusCountRejection) !== "undefined") {
            obj.todayHealthStatusCountRejection = jsonObject.todayHealthStatusCountRejection;
        }
        if (typeof(jsonObject.todayHealthStatusCountAbnormality) !== "undefined") {
            obj.todayHealthStatusCountAbnormality = jsonObject.todayHealthStatusCountAbnormality;
        }
        if (typeof(jsonObject.todayHealthStatusCountNoInput) !== "undefined") {
            obj.todayHealthStatusCountNoInput = jsonObject.todayHealthStatusCountNoInput;
        }
        if (typeof(jsonObject.todayHealthStatusCountAcceptation) !== "undefined") {
            obj.todayHealthStatusCountAcceptation = jsonObject.todayHealthStatusCountAcceptation;
        }
        if (typeof(jsonObject.todayHealthStatusCountNormality) !== "undefined") {
            obj.todayHealthStatusCountNormality = jsonObject.todayHealthStatusCountNormality;
        }
        if (typeof(jsonObject.last14daysHealthStatusCountRejection) !== "undefined") {
            obj.last14daysHealthStatusCountRejection = jsonObject.last14daysHealthStatusCountRejection;
        }
        if (typeof(jsonObject.last14daysHealthStatusCountAbnormality) !== "undefined") {
            obj.last14daysHealthStatusCountAbnormality = jsonObject.last14daysHealthStatusCountAbnormality;
        }
        if (typeof(jsonObject.last14daysHealthStatusCountNoInput) !== "undefined") {
            obj.last14daysHealthStatusCountNoInput = jsonObject.last14daysHealthStatusCountNoInput;
        }
        if (typeof(jsonObject.last14daysHealthStatusCountAcceptation) !== "undefined") {
            obj.last14daysHealthStatusCountAcceptation = jsonObject.last14daysHealthStatusCountAcceptation;
        }
        if (typeof(jsonObject.last14daysHealthStatusCountNormality) !== "undefined") {
            obj.last14daysHealthStatusCountNormality = jsonObject.last14daysHealthStatusCountNormality;
        }
        return obj;
  }
}

/*
var request_data = new AdminTournamentSummary()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.registerURL = null;
request_data.loginURL = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.inputStartAt = null;
request_data.inputEndAt = null;
request_data.tournamentStatus = null;
request_data.todayHealthStatusCountRejection = null;
request_data.todayHealthStatusCountAbnormality = null;
request_data.todayHealthStatusCountNoInput = null;
request_data.todayHealthStatusCountAcceptation = null;
request_data.todayHealthStatusCountNormality = null;
request_data.last14daysHealthStatusCountRejection = null;
request_data.last14daysHealthStatusCountAbnormality = null;
request_data.last14daysHealthStatusCountNoInput = null;
request_data.last14daysHealthStatusCountAcceptation = null;
request_data.last14daysHealthStatusCountNormality = null;
*/

/**
 * Update IP User
 * @constructor
 */
export class AdminUploadResult {
    constructor() {
        /**
         * @type { number } Number of successes
         */
        this.successCount = null;
        /**
         * @type { number } Number of failures
         */
        this.failureCount = null;
        /**
         * @type { boolean } If true the data is updated otherwise, no data will be updated.
         */
        this.updated = null;
        /**
         * @type { Array.<string> } List of reasons for failure
         */
        this.failureReasons = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminUploadResult }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminUploadResult();
        if (typeof(jsonObject.successCount) !== "undefined") {
            obj.successCount = jsonObject.successCount;
        }
        if (typeof(jsonObject.failureCount) !== "undefined") {
            obj.failureCount = jsonObject.failureCount;
        }
        if (typeof(jsonObject.updated) !== "undefined") {
            obj.updated = jsonObject.updated;
        }
        if (typeof(jsonObject.failureReasons) !== "undefined") {
            if (jsonObject.failureReasons !== null) {
                obj.failureReasons = [];
                for (let i = 0; i < jsonObject.failureReasons.length; i++) {
                    const item = jsonObject.failureReasons[i];
                    obj.failureReasons.push(item);
                }
            } else {
                obj.failureReasons = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new AdminUploadResult()
request_data.successCount = null;
request_data.failureCount = null;
request_data.updated = null;
request_data.failureReasons = null;
*/

/**
 * `Compatible with Involved People Rest API` User Account object 
 * @constructor
 */
export class AdminUser {
    constructor() {
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } Email
         */
        this.email = null;
        /**
         * @type { boolean } If true, you are using the initial password.
         */
        this.isInitialPassword = null;
        /**
         * @type { boolean } If true, initial information is not registered.
         */
        this.requireUserInfo = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { ?date } Birthday
         */
        this.birthday = null;
        /**
         * @type { ?number } Gender | * Male = 1 * Female = 2 * Other = 3
         */
        this.gender = null;
        /**
         * @type { ?number } Nationality ID
         */
        this.nationalityId = null;
        /**
         * @type { ?string } Nationality Name
         */
        this.nationalityName = null;
        /**
         * @type { ?string } Address
         */
        this.address = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
        /**
         * @type { boolean } Is it JTA User?
         */
        this.isJTA = null;
        /**
         * @type { boolean } Anemia/貧血
         */
        this.anemia = null;
        /**
         * @type { boolean } Asthma/ぜんそく
         */
        this.asthma = null;
        /**
         * @type { boolean } Heart disease/心臓病
         */
        this.heartDisease = null;
        /**
         * @type { boolean } Kidney disease/腎臓病
         */
        this.kidneyDisease = null;
        /**
         * @type { boolean } Epilepsy/てんかん
         */
        this.epilepsy = null;
        /**
         * @type { boolean } High blood pressure/高血圧
         */
        this.highBloodPressure = null;
        /**
         * @type { boolean } Diabetes/糖尿病
         */
        this.diabetes = null;
        /**
         * @type { boolean } Other/その他
         */
        this.other = null;
        /**
         * @type { ?string } Other reasons. If other is true, it is required.
         */
        this.otherText = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminUser }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminUser();
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.isInitialPassword) !== "undefined") {
            obj.isInitialPassword = jsonObject.isInitialPassword;
        }
        if (typeof(jsonObject.requireUserInfo) !== "undefined") {
            obj.requireUserInfo = jsonObject.requireUserInfo;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.gender) !== "undefined") {
            obj.gender = jsonObject.gender;
        }
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityName) !== "undefined") {
            obj.nationalityName = jsonObject.nationalityName;
        }
        if (typeof(jsonObject.address) !== "undefined") {
            obj.address = jsonObject.address;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        if (typeof(jsonObject.isJTA) !== "undefined") {
            obj.isJTA = jsonObject.isJTA;
        }
        if (typeof(jsonObject.anemia) !== "undefined") {
            obj.anemia = jsonObject.anemia;
        }
        if (typeof(jsonObject.asthma) !== "undefined") {
            obj.asthma = jsonObject.asthma;
        }
        if (typeof(jsonObject.heartDisease) !== "undefined") {
            obj.heartDisease = jsonObject.heartDisease;
        }
        if (typeof(jsonObject.kidneyDisease) !== "undefined") {
            obj.kidneyDisease = jsonObject.kidneyDisease;
        }
        if (typeof(jsonObject.epilepsy) !== "undefined") {
            obj.epilepsy = jsonObject.epilepsy;
        }
        if (typeof(jsonObject.highBloodPressure) !== "undefined") {
            obj.highBloodPressure = jsonObject.highBloodPressure;
        }
        if (typeof(jsonObject.diabetes) !== "undefined") {
            obj.diabetes = jsonObject.diabetes;
        }
        if (typeof(jsonObject.other) !== "undefined") {
            obj.other = jsonObject.other;
        }
        if (typeof(jsonObject.otherText) !== "undefined") {
            obj.otherText = jsonObject.otherText;
        }
        return obj;
  }
}

/*
var request_data = new AdminUser()
request_data.userId = null;
request_data.email = null;
request_data.isInitialPassword = null;
request_data.requireUserInfo = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.birthday = null;
request_data.gender = null;
request_data.nationalityId = null;
request_data.nationalityName = null;
request_data.address = null;
request_data.phoneNo = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
request_data.isJTA = null;
request_data.anemia = null;
request_data.asthma = null;
request_data.heartDisease = null;
request_data.kidneyDisease = null;
request_data.epilepsy = null;
request_data.highBloodPressure = null;
request_data.diabetes = null;
request_data.other = null;
request_data.otherText = null;
*/

/**
 * User record details
 * @constructor
 */
export class AdminUserRecordDetail {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } First name
         */
        this.firstName = null;
        /**
         * @type { string } Last name
         */
        this.lastName = null;
        /**
         * @type { string } email
         */
        this.email = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { number } Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
         */
        this.healthStatus = null;
        /**
         * @type { date } 
         */
        this.date = null;
        /**
         * @type { string } Body temp (xx.y)
         */
        this.bodyTemp = null;
        /**
         * @type { ?Array.<string> } List of alert
         */
        this.alerts = null;
        /**
         * @type { string } Remarks
         */
        this.remarks = null;
        /**
         * @type { Array.<AdminRecordAnswer> } Record answers
         */
        this.answers = null;
        /**
         * @type { ?number } Heat stroke question tiredness * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionTiredness = null;
        /**
         * @type { ?number } Heat stroke question appetite * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionAppetite = null;
        /**
         * @type { ?number } Heat stroke question sleep * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionSleep = null;
        /**
         * @type { ?number } Heat stroke question sweat * 1: Very good / 非常に良い * 2: Good / 良い * 3: Usually / 普通 * 4: Bad / 悪い * 5: Very bad / 非常に悪い 
         */
        this.heatStrokeQuestionSweat = null;
        /**
         * @type { ?number } Pee color * 1: Light / うすい * 3: Usually / 普通 * 5: Dark / 濃い * 7: Very dark / とても濃い 
         */
        this.peeColor = null;
        /**
         * @type { ?number } Ever had heat stroke * 1: Yes / はい * 2: No　/ いいえ 
         */
        this.everHadHeatStroke = null;
        /**
         * @type { ?string } Heatstroke Score
         */
        this.heatStrokeScore = null;
        /**
         * @type { boolean } Approval
         */
        this.approval = null;
        /**
         * @type { boolean } Rejection
         */
        this.rejection = null;
        /**
         * @type { ?date } Previous date
         */
        this.prevDate = null;
        /**
         * @type { ?date } Next date
         */
        this.nextDate = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminUserRecordDetail }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminUserRecordDetail();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.healthStatus) !== "undefined") {
            obj.healthStatus = jsonObject.healthStatus;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.alerts) !== "undefined") {
            if (jsonObject.alerts !== null) {
                obj.alerts = [];
                for (let i = 0; i < jsonObject.alerts.length; i++) {
                    const item = jsonObject.alerts[i];
                    obj.alerts.push(item);
                }
            } else {
                obj.alerts = null;
            }
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.answers) !== "undefined") {
            if (jsonObject.answers !== null) {
                obj.answers = [];
                for (let i = 0; i < jsonObject.answers.length; i++) {
                    const item = AdminRecordAnswer.createFromJson(jsonObject.answers[i]);
                    obj.answers.push(item);
                }
            } else {
                obj.answers = null;
            }
        }
        if (typeof(jsonObject.heatStrokeQuestionTiredness) !== "undefined") {
            obj.heatStrokeQuestionTiredness = jsonObject.heatStrokeQuestionTiredness;
        }
        if (typeof(jsonObject.heatStrokeQuestionAppetite) !== "undefined") {
            obj.heatStrokeQuestionAppetite = jsonObject.heatStrokeQuestionAppetite;
        }
        if (typeof(jsonObject.heatStrokeQuestionSleep) !== "undefined") {
            obj.heatStrokeQuestionSleep = jsonObject.heatStrokeQuestionSleep;
        }
        if (typeof(jsonObject.heatStrokeQuestionSweat) !== "undefined") {
            obj.heatStrokeQuestionSweat = jsonObject.heatStrokeQuestionSweat;
        }
        if (typeof(jsonObject.peeColor) !== "undefined") {
            obj.peeColor = jsonObject.peeColor;
        }
        if (typeof(jsonObject.everHadHeatStroke) !== "undefined") {
            obj.everHadHeatStroke = jsonObject.everHadHeatStroke;
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        if (typeof(jsonObject.approval) !== "undefined") {
            obj.approval = jsonObject.approval;
        }
        if (typeof(jsonObject.rejection) !== "undefined") {
            obj.rejection = jsonObject.rejection;
        }
        if (typeof(jsonObject.prevDate) !== "undefined") {
            obj.prevDate = jsonObject.prevDate;
        }
        if (typeof(jsonObject.nextDate) !== "undefined") {
            obj.nextDate = jsonObject.nextDate;
        }
        return obj;
  }
}

/*
var request_data = new AdminUserRecordDetail()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.userId = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.email = null;
request_data.phoneNo = null;
request_data.healthStatus = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.alerts = null;
request_data.remarks = null;
request_data.answers = null;
request_data.heatStrokeQuestionTiredness = null;
request_data.heatStrokeQuestionAppetite = null;
request_data.heatStrokeQuestionSleep = null;
request_data.heatStrokeQuestionSweat = null;
request_data.peeColor = null;
request_data.everHadHeatStroke = null;
request_data.heatStrokeScore = null;
request_data.approval = null;
request_data.rejection = null;
request_data.prevDate = null;
request_data.nextDate = null;
*/

/**
 * User record
 * @constructor
 */
export class AdminUserRecordItem {
    constructor() {
        /**
         * @type { number } Health status * 0: All(Default) / すべて * 1: Rejection / 入場拒否 * 2: Abnormality / 異常あり * 3: No Input / 未入力 * 4: Acceptation / 大会承認済 * 5: Normality / 異常なし 
         */
        this.healthStatus = null;
        /**
         * @type { date } 
         */
        this.date = null;
        /**
         * @type { string } Body temp (xx.y)
         */
        this.bodyTemp = null;
        /**
         * @type { Array.<string> } List of alert
         */
        this.alerts = null;
        /**
         * @type { string } Remarks
         */
        this.remarks = null;
        /**
         * @type { ?string } Heatstroke Score
         */
        this.heatStrokeScore = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminUserRecordItem }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminUserRecordItem();
        if (typeof(jsonObject.healthStatus) !== "undefined") {
            obj.healthStatus = jsonObject.healthStatus;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.alerts) !== "undefined") {
            if (jsonObject.alerts !== null) {
                obj.alerts = [];
                for (let i = 0; i < jsonObject.alerts.length; i++) {
                    const item = jsonObject.alerts[i];
                    obj.alerts.push(item);
                }
            } else {
                obj.alerts = null;
            }
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        return obj;
  }
}

/*
var request_data = new AdminUserRecordItem()
request_data.healthStatus = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.alerts = null;
request_data.remarks = null;
request_data.heatStrokeScore = null;
*/

/**
 * User record results
 * @constructor
 */
export class AdminUserRecordResult {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { AdminUser } 
         */
        this.user = null;
        /**
         * @type { string } User role ID
         */
        this.userRoleId = null;
        /**
         * @type { string } User role
         */
        this.userRole = null;
        /**
         * @type { string } Organization
         */
        this.organization = null;
        /**
         * @type { number } Enter status * 1: Good / ○ * 2: Bad / × 
         */
        this.enterStatus = null;
        /**
         * @type { Array.<number> } List of reasons for bad. 
         */
        this.badReasons = null;
        /**
         * @type { Array.<AdminUserRecordItem> } List of reasons for bad. 
         */
        this.items = null;
        /**
         * @type { number } Account status * 1: Enabled / 有効 * 2: Disabled / 停止 
         */
        this.accountStatus = null;
        /**
         * @type { ?string } Previous user ID
         */
        this.prevUserId = null;
        /**
         * @type { ?string } Next user ID
         */
        this.nextUserId = null;
        /**
         * @type { ?string } Previous user name
         */
        this.prevUserName = null;
        /**
         * @type { ?string } Next user name
         */
        this.nextUserName = null;
        /**
         * @type { ?string } Input sort type
         */
        this.sortItem = null;
        /**
         * @type { ?string } Input sort order
         */
        this.orderItem = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminUserRecordResult }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminUserRecordResult();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.userRole) !== "undefined") {
            obj.userRole = jsonObject.userRole;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.enterStatus) !== "undefined") {
            obj.enterStatus = jsonObject.enterStatus;
        }
        if (typeof(jsonObject.badReasons) !== "undefined") {
            if (jsonObject.badReasons !== null) {
                obj.badReasons = [];
                for (let i = 0; i < jsonObject.badReasons.length; i++) {
                    const item = integer.createFromJson(jsonObject.badReasons[i]);
                    obj.badReasons.push(item);
                }
            } else {
                obj.badReasons = null;
            }
        }
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = AdminUserRecordItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.accountStatus) !== "undefined") {
            obj.accountStatus = jsonObject.accountStatus;
        }
        if (typeof(jsonObject.prevUserId) !== "undefined") {
            obj.prevUserId = jsonObject.prevUserId;
        }
        if (typeof(jsonObject.nextUserId) !== "undefined") {
            obj.nextUserId = jsonObject.nextUserId;
        }
        if (typeof(jsonObject.prevUserName) !== "undefined") {
            obj.prevUserName = jsonObject.prevUserName;
        }
        if (typeof(jsonObject.nextUserName) !== "undefined") {
            obj.nextUserName = jsonObject.nextUserName;
        }
        if (typeof(jsonObject.sortItem) !== "undefined") {
            obj.sortItem = jsonObject.sortItem;
        }
        if (typeof(jsonObject.orderItem) !== "undefined") {
            obj.orderItem = jsonObject.orderItem;
        }
        return obj;
  }
}

/*
var request_data = new AdminUserRecordResult()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.user = null;
request_data.userRoleId = null;
request_data.userRole = null;
request_data.organization = null;
request_data.enterStatus = null;
request_data.badReasons = null;
request_data.items = null;
request_data.accountStatus = null;
request_data.prevUserId = null;
request_data.nextUserId = null;
request_data.prevUserName = null;
request_data.nextUserName = null;
request_data.sortItem = null;
request_data.orderItem = null;
*/

/**
 * `Compatible with Involved People Rest API` ValidationError object. 
 * @constructor
 */
export class AdminValidationError {
    constructor() {
        /**
         * @type { string } Key name with error
         */
        this.name = null;
        /**
         * @type { string } Error message
         */
        this.message = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { AdminValidationError }
     */
    static createFromJson (jsonObject) {
        const obj = new AdminValidationError();
        if (typeof(jsonObject.name) !== "undefined") {
            obj.name = jsonObject.name;
        }
        if (typeof(jsonObject.message) !== "undefined") {
            obj.message = jsonObject.message;
        }
        return obj;
  }
}

/*
var request_data = new AdminValidationError()
request_data.name = null;
request_data.message = null;
*/

/**
 * Set approval
 * @constructor
 */
export class SetAdminApproval {
    constructor() {
        /**
         * @type { string } Date
         */
        this.date = null;
        /**
         * @type { boolean } * true: Approval * false: Cancel approval 
         */
        this.approval = null;
        /**
         * @type { boolean } * true: It will be update in all tournament approved. * false: Only on specified days. 
         */
        this.allCheck = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminApproval }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminApproval();
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.approval) !== "undefined") {
            obj.approval = jsonObject.approval;
        }
        if (typeof(jsonObject.allCheck) !== "undefined") {
            obj.allCheck = jsonObject.allCheck;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminApproval()
request_data.date = null;
request_data.approval = null;
request_data.allCheck = null;
*/

/**
 * `Compatible with Involved People Rest API` Change password object 
 * @constructor
 */
export class SetAdminChangePassword {
    constructor() {
        /**
         * @type { string } Old password
         */
        this.oldPassword = null;
        /**
         * @type { string } New password
         */
        this.newPassword = null;
        /**
         * @type { string } New password confirm
         */
        this.newPasswordConfirm = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminChangePassword }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminChangePassword();
        if (typeof(jsonObject.oldPassword) !== "undefined") {
            obj.oldPassword = jsonObject.oldPassword;
        }
        if (typeof(jsonObject.newPassword) !== "undefined") {
            obj.newPassword = jsonObject.newPassword;
        }
        if (typeof(jsonObject.newPasswordConfirm) !== "undefined") {
            obj.newPasswordConfirm = jsonObject.newPasswordConfirm;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminChangePassword()
request_data.oldPassword = null;
request_data.newPassword = null;
request_data.newPasswordConfirm = null;
*/

/**
 * Delete user object
 * @constructor
 */
export class SetAdminDeleteUser {
    constructor() {
        /**
         * @type { string } Authentication password. 
         */
        this.authenticationPassword = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminDeleteUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminDeleteUser();
        if (typeof(jsonObject.authenticationPassword) !== "undefined") {
            obj.authenticationPassword = jsonObject.authenticationPassword;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminDeleteUser()
request_data.authenticationPassword = null;
*/

/**
 * Set IP User
 * @constructor
 */
export class SetAdminIpUser {
    constructor() {
        /**
         * @type { string } Email
         */
        this.email = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { date } Birthday
         */
        this.birthday = null;
        /**
         * @type { number } Role ID
         */
        this.userRoleId = null;
        /**
         * @type { ?string } Organization
         */
        this.organization = null;
        /**
         * @type { boolean } Require answers in the last 14 days
         */
        this.pastAnswersRequired = null;
        /**
         * @type { ?string } Remarks
         */
        this.remarks = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminIpUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminIpUser();
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.pastAnswersRequired) !== "undefined") {
            obj.pastAnswersRequired = jsonObject.pastAnswersRequired;
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminIpUser()
request_data.email = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.birthday = null;
request_data.userRoleId = null;
request_data.organization = null;
request_data.pastAnswersRequired = null;
request_data.remarks = null;
*/

/**
 * Set tournament object
 * @constructor
 */
export class SetAdminJTATournament {
    constructor() {
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { number } Host prefecture IDA
         */
        this.hostPrefectureId = null;
        /**
         * @type { ?string } Host organization
         */
        this.hostOrganization = null;
        /**
         * @type { ?string } Inquiry phone no
         */
        this.inquiryPhoneNo = null;
        /**
         * @type { ?string } Inquiry email
         */
        this.inquiryEmail = null;
        /**
         * @type { ?string } Tournament admin email
         */
        this.tournamentAdminEmail = null;
        /**
         * @type { boolean } Require answers in the last 14 days
         */
        this.pastAnswersRequired = null;
        /**
         * @type { boolean } Has heat stroke.
         */
        this.hasHeatStroke = null;
        /**
         * @type { ?string } Billing destination
         */
        this.billingDestination = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminJTATournament }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminJTATournament();
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.hostPrefectureId) !== "undefined") {
            obj.hostPrefectureId = jsonObject.hostPrefectureId;
        }
        if (typeof(jsonObject.hostOrganization) !== "undefined") {
            obj.hostOrganization = jsonObject.hostOrganization;
        }
        if (typeof(jsonObject.inquiryPhoneNo) !== "undefined") {
            obj.inquiryPhoneNo = jsonObject.inquiryPhoneNo;
        }
        if (typeof(jsonObject.inquiryEmail) !== "undefined") {
            obj.inquiryEmail = jsonObject.inquiryEmail;
        }
        if (typeof(jsonObject.tournamentAdminEmail) !== "undefined") {
            obj.tournamentAdminEmail = jsonObject.tournamentAdminEmail;
        }
        if (typeof(jsonObject.pastAnswersRequired) !== "undefined") {
            obj.pastAnswersRequired = jsonObject.pastAnswersRequired;
        }
        if (typeof(jsonObject.hasHeatStroke) !== "undefined") {
            obj.hasHeatStroke = jsonObject.hasHeatStroke;
        }
        if (typeof(jsonObject.billingDestination) !== "undefined") {
            obj.billingDestination = jsonObject.billingDestination;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminJTATournament()
request_data.tournamentName = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.hostPrefectureId = null;
request_data.hostOrganization = null;
request_data.inquiryPhoneNo = null;
request_data.inquiryEmail = null;
request_data.tournamentAdminEmail = null;
request_data.pastAnswersRequired = null;
request_data.hasHeatStroke = null;
request_data.billingDestination = null;
*/

/**
 * Set user
 * @constructor
 */
export class SetAdminJTAUser {
    constructor() {
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { string } E-mail
         */
        this.email = null;
        /**
         * @type { date } Birthday
         */
        this.birthday = null;
        /**
         * @type { boolean } Is it JTA User?
         */
        this.isJTA = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminJTAUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminJTAUser();
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.isJTA) !== "undefined") {
            obj.isJTA = jsonObject.isJTA;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminJTAUser()
request_data.firstName = null;
request_data.lastName = null;
request_data.email = null;
request_data.birthday = null;
request_data.isJTA = null;
*/

/**
 * Change the account status
 * @constructor
 */
export class SetAdminJTAUserChangeStatus {
    constructor() {
        /**
         * @type { number } Account status * 1: Enabled / 有効 * 2: Disabled / 停止 
         */
        this.accountStatus = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminJTAUserChangeStatus }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminJTAUserChangeStatus();
        if (typeof(jsonObject.accountStatus) !== "undefined") {
            obj.accountStatus = jsonObject.accountStatus;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminJTAUserChangeStatus()
request_data.accountStatus = null;
*/

/**
 * Set rejection
 * @constructor
 */
export class SetAdminRejection {
    constructor() {
        /**
         * @type { string } Date
         */
        this.date = null;
        /**
         * @type { boolean } * true: Rejection * false: Cancel rejection 
         */
        this.rejection = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminRejection }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminRejection();
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.rejection) !== "undefined") {
            obj.rejection = jsonObject.rejection;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminRejection()
request_data.date = null;
request_data.rejection = null;
*/

/**
 * Set reminder
 * @constructor
 */
export class SetAdminReminder {
    constructor() {
        /**
         * @type { string } Reminder Title
         */
        this.reminderTitle = null;
        /**
         * @type { number } Send hour (0-23)
         */
        this.hour = null;
        /**
         * @type { number } Send minute (0-59)
         */
        this.minute = null;
        /**
         * @type { number } Send target ID * 1: All involved people / 関係者全員 
         */
        this.sendTargetId = null;
        /**
         * @type { string } Reminder subject
         */
        this.subject = null;
        /**
         * @type { string } Reminder body
         */
        this.body = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminReminder }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminReminder();
        if (typeof(jsonObject.reminderTitle) !== "undefined") {
            obj.reminderTitle = jsonObject.reminderTitle;
        }
        if (typeof(jsonObject.hour) !== "undefined") {
            obj.hour = jsonObject.hour;
        }
        if (typeof(jsonObject.minute) !== "undefined") {
            obj.minute = jsonObject.minute;
        }
        if (typeof(jsonObject.sendTargetId) !== "undefined") {
            obj.sendTargetId = jsonObject.sendTargetId;
        }
        if (typeof(jsonObject.subject) !== "undefined") {
            obj.subject = jsonObject.subject;
        }
        if (typeof(jsonObject.body) !== "undefined") {
            obj.body = jsonObject.body;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminReminder()
request_data.reminderTitle = null;
request_data.hour = null;
request_data.minute = null;
request_data.sendTargetId = null;
request_data.subject = null;
request_data.body = null;
*/

/**
 * `Compatible with Involved People Rest API` Set reset password 
 * @constructor
 */
export class SetAdminResetPassword {
    constructor() {
        /**
         * @type { string } Email
         */
        this.email = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminResetPassword }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminResetPassword();
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminResetPassword()
request_data.email = null;
*/

/**
 * `Compatible with Involved People Rest API` Set reset password finish\" 
 * @constructor
 */
export class SetAdminResetPasswordFinish {
    constructor() {
        /**
         * @type { string } New Password
         */
        this.newPassword = null;
        /**
         * @type { string } New Password Confirm
         */
        this.newPasswordConfirm = null;
        /**
         * @type { string } Reset password token
         */
        this.token = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminResetPasswordFinish }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminResetPasswordFinish();
        if (typeof(jsonObject.newPassword) !== "undefined") {
            obj.newPassword = jsonObject.newPassword;
        }
        if (typeof(jsonObject.newPasswordConfirm) !== "undefined") {
            obj.newPasswordConfirm = jsonObject.newPasswordConfirm;
        }
        if (typeof(jsonObject.token) !== "undefined") {
            obj.token = jsonObject.token;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminResetPasswordFinish()
request_data.newPassword = null;
request_data.newPasswordConfirm = null;
request_data.token = null;
*/

/**
 * Set scan log
 * @constructor
 */
export class SetAdminScan {
    constructor() {
        /**
         * @type { string } User identification key
         */
        this.userId = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminScan }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminScan();
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminScan()
request_data.userId = null;
*/

/**
 * Update IP User
 * @constructor
 */
export class SetAdminUpdateIpUser {
    constructor() {
        /**
         * @type { number } Role ID
         */
        this.userRoleId = null;
        /**
         * @type { ?string } Organization
         */
        this.organization = null;
        /**
         * @type { boolean } Require answers in the last 14 days
         */
        this.pastAnswersRequired = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminUpdateIpUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminUpdateIpUser();
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        if (typeof(jsonObject.pastAnswersRequired) !== "undefined") {
            obj.pastAnswersRequired = jsonObject.pastAnswersRequired;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminUpdateIpUser()
request_data.userRoleId = null;
request_data.organization = null;
request_data.pastAnswersRequired = null;
*/

/**
 * Set user
 * @constructor
 */
export class SetAdminUpdateJTAUser {
    constructor() {
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { string } E-mail
         */
        this.email = null;
        /**
         * @type { date } Birthday
         */
        this.birthday = null;
        /**
         * @type { ?number } Gender | * Male = 1 * Female = 2 * Other = 3
         */
        this.gender = null;
        /**
         * @type { ?number } Nationality ID
         */
        this.nationalityId = null;
        /**
         * @type { ?string } Address
         */
        this.address = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
        /**
         * @type { ?boolean } Anemia/貧血
         */
        this.anemia = null;
        /**
         * @type { ?boolean } Asthma/ぜんそく
         */
        this.asthma = null;
        /**
         * @type { ?boolean } Heart disease/心臓病
         */
        this.heartDisease = null;
        /**
         * @type { ?boolean } Kidney disease/腎臓病
         */
        this.kidneyDisease = null;
        /**
         * @type { ?boolean } Epilepsy/てんかん
         */
        this.epilepsy = null;
        /**
         * @type { ?boolean } High blood pressure/高血圧
         */
        this.highBloodPressure = null;
        /**
         * @type { ?boolean } Diabetes/糖尿病
         */
        this.diabetes = null;
        /**
         * @type { ?boolean } Others/その他
         */
        this.other = null;
        /**
         * @type { ?string } Other reasons. If other is true, it is required.
         */
        this.otherText = null;
        /**
         * @type { boolean } Is it JTA User?
         */
        this.isJTA = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminUpdateJTAUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminUpdateJTAUser();
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.gender) !== "undefined") {
            obj.gender = jsonObject.gender;
        }
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.address) !== "undefined") {
            obj.address = jsonObject.address;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        if (typeof(jsonObject.anemia) !== "undefined") {
            obj.anemia = jsonObject.anemia;
        }
        if (typeof(jsonObject.asthma) !== "undefined") {
            obj.asthma = jsonObject.asthma;
        }
        if (typeof(jsonObject.heartDisease) !== "undefined") {
            obj.heartDisease = jsonObject.heartDisease;
        }
        if (typeof(jsonObject.kidneyDisease) !== "undefined") {
            obj.kidneyDisease = jsonObject.kidneyDisease;
        }
        if (typeof(jsonObject.epilepsy) !== "undefined") {
            obj.epilepsy = jsonObject.epilepsy;
        }
        if (typeof(jsonObject.highBloodPressure) !== "undefined") {
            obj.highBloodPressure = jsonObject.highBloodPressure;
        }
        if (typeof(jsonObject.diabetes) !== "undefined") {
            obj.diabetes = jsonObject.diabetes;
        }
        if (typeof(jsonObject.other) !== "undefined") {
            obj.other = jsonObject.other;
        }
        if (typeof(jsonObject.otherText) !== "undefined") {
            obj.otherText = jsonObject.otherText;
        }
        if (typeof(jsonObject.isJTA) !== "undefined") {
            obj.isJTA = jsonObject.isJTA;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminUpdateJTAUser()
request_data.firstName = null;
request_data.lastName = null;
request_data.email = null;
request_data.birthday = null;
request_data.gender = null;
request_data.nationalityId = null;
request_data.address = null;
request_data.phoneNo = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
request_data.anemia = null;
request_data.asthma = null;
request_data.heartDisease = null;
request_data.kidneyDisease = null;
request_data.epilepsy = null;
request_data.highBloodPressure = null;
request_data.diabetes = null;
request_data.other = null;
request_data.otherText = null;
request_data.isJTA = null;
*/

/**
 * Set Update User Account object. Do not update null params.
 * @constructor
 */
export class SetAdminUpdateUser {
    constructor() {
        /**
         * @type { boolean } If true for the first update.
         */
        this.initialUpdate = null;
        /**
         * @type { ?string } Authentication password. password / email / firstName/ lastName / birthday is included, and initialUpdate is false. Null if not needed. 
         */
        this.authenticationPassword = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { boolean } Update flag First name
         */
        this.firstNameUpdate = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { boolean } Update flag Last name
         */
        this.lastNameUpdate = null;
        /**
         * @type { ?string } Password
         */
        this.password = null;
        /**
         * @type { ?string } Password confirm
         */
        this.passwordConfirm = null;
        /**
         * @type { boolean } Update flag Password
         */
        this.passwordUpdate = null;
        /**
         * @type { ?date } Birthday If initialUpdate is true, it will not be updated. 
         */
        this.birthday = null;
        /**
         * @type { boolean } Update flag birthday
         */
        this.birthdayUpdate = null;
        /**
         * @type { ?string } E-mail
         */
        this.email = null;
        /**
         * @type { boolean } Update flag E-mail
         */
        this.emailUpdate = null;
        /**
         * @type { ?number } Gender | * Male = 1 * Female = 2 * Other = 3
         */
        this.gender = null;
        /**
         * @type { boolean } Update flag gender
         */
        this.genderUpdate = null;
        /**
         * @type { ?number } Nationality ID
         */
        this.nationalityId = null;
        /**
         * @type { boolean } Update flag Nationality ID
         */
        this.nationalityIdUpdate = null;
        /**
         * @type { ?string } Address
         */
        this.address = null;
        /**
         * @type { boolean } Update flag Address
         */
        this.addressUpdate = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { boolean } Update flag Telephone No
         */
        this.phoneNoUpdate = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { boolean } Update flag vaccination
         */
        this.coronaVaccinationUpdate = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
        /**
         * @type { boolean } Update flag PCR test
         */
        this.latestPcrTestUpdate = null;
        /**
         * @type { ?boolean } Anemia/貧血
         */
        this.anemia = null;
        /**
         * @type { ?boolean } Asthma/ぜんそく
         */
        this.asthma = null;
        /**
         * @type { ?boolean } Heart disease/心臓病
         */
        this.heartDisease = null;
        /**
         * @type { ?boolean } Kidney disease/腎臓病
         */
        this.kidneyDisease = null;
        /**
         * @type { ?boolean } Epilepsy/てんかん
         */
        this.epilepsy = null;
        /**
         * @type { ?boolean } High blood pressure/高血圧
         */
        this.highBloodPressure = null;
        /**
         * @type { ?boolean } Diabetes/糖尿病
         */
        this.diabetes = null;
        /**
         * @type { ?boolean } Others/その他
         */
        this.other = null;
        /**
         * @type { ?string } Other reasons. If other is true, it is required.
         */
        this.otherText = null;
        /**
         * @type { ?boolean } Update flag medical history. Default is false
         */
        this.medicalHistoryUpdate = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetAdminUpdateUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetAdminUpdateUser();
        if (typeof(jsonObject.initialUpdate) !== "undefined") {
            obj.initialUpdate = jsonObject.initialUpdate;
        }
        if (typeof(jsonObject.authenticationPassword) !== "undefined") {
            obj.authenticationPassword = jsonObject.authenticationPassword;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.firstNameUpdate) !== "undefined") {
            obj.firstNameUpdate = jsonObject.firstNameUpdate;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.lastNameUpdate) !== "undefined") {
            obj.lastNameUpdate = jsonObject.lastNameUpdate;
        }
        if (typeof(jsonObject.password) !== "undefined") {
            obj.password = jsonObject.password;
        }
        if (typeof(jsonObject.passwordConfirm) !== "undefined") {
            obj.passwordConfirm = jsonObject.passwordConfirm;
        }
        if (typeof(jsonObject.passwordUpdate) !== "undefined") {
            obj.passwordUpdate = jsonObject.passwordUpdate;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.birthdayUpdate) !== "undefined") {
            obj.birthdayUpdate = jsonObject.birthdayUpdate;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.emailUpdate) !== "undefined") {
            obj.emailUpdate = jsonObject.emailUpdate;
        }
        if (typeof(jsonObject.gender) !== "undefined") {
            obj.gender = jsonObject.gender;
        }
        if (typeof(jsonObject.genderUpdate) !== "undefined") {
            obj.genderUpdate = jsonObject.genderUpdate;
        }
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityIdUpdate) !== "undefined") {
            obj.nationalityIdUpdate = jsonObject.nationalityIdUpdate;
        }
        if (typeof(jsonObject.address) !== "undefined") {
            obj.address = jsonObject.address;
        }
        if (typeof(jsonObject.addressUpdate) !== "undefined") {
            obj.addressUpdate = jsonObject.addressUpdate;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.phoneNoUpdate) !== "undefined") {
            obj.phoneNoUpdate = jsonObject.phoneNoUpdate;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.coronaVaccinationUpdate) !== "undefined") {
            obj.coronaVaccinationUpdate = jsonObject.coronaVaccinationUpdate;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        if (typeof(jsonObject.latestPcrTestUpdate) !== "undefined") {
            obj.latestPcrTestUpdate = jsonObject.latestPcrTestUpdate;
        }
        if (typeof(jsonObject.anemia) !== "undefined") {
            obj.anemia = jsonObject.anemia;
        }
        if (typeof(jsonObject.asthma) !== "undefined") {
            obj.asthma = jsonObject.asthma;
        }
        if (typeof(jsonObject.heartDisease) !== "undefined") {
            obj.heartDisease = jsonObject.heartDisease;
        }
        if (typeof(jsonObject.kidneyDisease) !== "undefined") {
            obj.kidneyDisease = jsonObject.kidneyDisease;
        }
        if (typeof(jsonObject.epilepsy) !== "undefined") {
            obj.epilepsy = jsonObject.epilepsy;
        }
        if (typeof(jsonObject.highBloodPressure) !== "undefined") {
            obj.highBloodPressure = jsonObject.highBloodPressure;
        }
        if (typeof(jsonObject.diabetes) !== "undefined") {
            obj.diabetes = jsonObject.diabetes;
        }
        if (typeof(jsonObject.other) !== "undefined") {
            obj.other = jsonObject.other;
        }
        if (typeof(jsonObject.otherText) !== "undefined") {
            obj.otherText = jsonObject.otherText;
        }
        if (typeof(jsonObject.medicalHistoryUpdate) !== "undefined") {
            obj.medicalHistoryUpdate = jsonObject.medicalHistoryUpdate;
        }
        return obj;
  }
}

/*
var request_data = new SetAdminUpdateUser()
request_data.initialUpdate = null;
request_data.authenticationPassword = null;
request_data.firstName = null;
request_data.firstNameUpdate = null;
request_data.lastName = null;
request_data.lastNameUpdate = null;
request_data.password = null;
request_data.passwordConfirm = null;
request_data.passwordUpdate = null;
request_data.birthday = null;
request_data.birthdayUpdate = null;
request_data.email = null;
request_data.emailUpdate = null;
request_data.gender = null;
request_data.genderUpdate = null;
request_data.nationalityId = null;
request_data.nationalityIdUpdate = null;
request_data.address = null;
request_data.addressUpdate = null;
request_data.phoneNo = null;
request_data.phoneNoUpdate = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.coronaVaccinationUpdate = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
request_data.latestPcrTestUpdate = null;
request_data.anemia = null;
request_data.asthma = null;
request_data.heartDisease = null;
request_data.kidneyDisease = null;
request_data.epilepsy = null;
request_data.highBloodPressure = null;
request_data.diabetes = null;
request_data.other = null;
request_data.otherText = null;
request_data.medicalHistoryUpdate = null;
*/


var mergeObject = function (obj1, obj2) {
    if (obj1 == null) {
        obj1 = {};
    }
    if (obj2 == null) {
        obj2 = {};
    }
    for (var attrname in obj2) {
        if (obj2.hasOwnProperty(attrname)) {
            obj1[attrname] = obj2[attrname];
        }
    }
    return obj1;
};
