var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.reloadIndex,staticClass:"wrapper",attrs:{"id":"wrapper"}},[_c('div',{staticClass:"wrapper-sidebar sticky",attrs:{"id":"wrapper-sidebar"}},[_c('div',{staticClass:"wrapper-sidebar-header"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/home"}},[_c('img',{attrs:{"alt":"","src":require("../../assets/images/sidebar/jta_logo.png")}})]),_vm._m(0),_c('button',{staticClass:"btn btn-default menu wrapper-sidebar-menu-toggle",attrs:{"id":"wrapper-sidebar-menu-toggle"},on:{"click":_vm.menuToggle}},[_c('img',{attrs:{"alt":"","src":require("../../assets/images/sidebar/list.svg")}})])],1),_c('ul',{staticClass:"nav flex-column flex-nowrap overflow-hidden",attrs:{"id":"nav-wrap"}},_vm._l((_vm.sidebar.menu),function(menu,index){
var _obj;
return _c('li',{key:'menu-item-'+index,staticClass:"nav-menu",class:( _obj = {
                  active: _vm.$route.name === menu.route ||
                      ('submenu' in menu && menu.submenu.some(function (x) { return x.route === _vm.$route.name; })) ||
                      ('from' in _vm.$route.params && _vm.$route.params.from === menu.route) ||
                      (_vm.from === menu.route),
                  'nav-item': !('submenu' in menu)
              }, _obj[menu.class] = ('class' in menu), _obj )},[(!_vm.isJTA && menu.id === 2)?void 0:[(menu.route)?_c('router-link',{attrs:{"to":{name: menu.route}},on:{"click":function($event){return _vm.menuToggle()}}},[_c('span',{staticClass:"m-l-30 font-weight-medium font-12 text-clamp-1"},[_vm._v(_vm._s(menu.name))])]):_c('span',{staticClass:"m-l-30 font-weight-medium font-12 text-clamp-1"},[_vm._v(_vm._s(menu.name))])],('submenu' in menu)?_c('ul',{staticClass:"nav-submenu-wrap"},_vm._l((menu.submenu),function(submenu,subIndex){
              var _obj;
return _c('li',{key:'submenu-item-'+index+subIndex,class:( _obj = {
              active: _vm.$route.name === submenu.route,
              'nav-item': !('submenu' in submenu)
            }, _obj[menu.class] = ('class' in submenu), _obj ),on:{"mouseenter":function($event){return _vm.setMenuHoverState(index, subIndex)},"mouseleave":function($event){return _vm.preventMenuState(index, subIndex)}}},[(!_vm.isJTA && menu.id === 2)?void 0:[_c('router-link',{staticClass:"nav-link",style:({ 'background-image' :  'url(' + _vm.getIconUrl(index, subIndex) + ')'}),attrs:{"to":{name: submenu.route}}},[_c('h5',[_vm._v(_vm._s(submenu.name))])])]],2)}),0):_vm._e()],2)}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"logo-title"},[_vm._v("健康情報管理システム"),_c('br'),_vm._v("HeaLo")])}]

export { render, staticRenderFns }